/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

 .datepicker {
  background-color: #fff;
  border: 1px solid $border;
  font-family: "Roboto", sans-serif;
  font-size: inherit;
  padding: 10px;
  margin: 1px 0 0;
  display: none;
  width: auto !important;
  z-index: 5 !important;
  direction: ltr;
  border-radius: 7px;
  box-shadow: 0px 16px 18px rgba(104, 113, 123, 0.2);

  &.dropdown-menu {
    color: #3b4863;
  }
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;

  &.dropdown-menu {
    left: auto;
  }

  table tr td span {
    float: right;
  }
}

.datepicker-dropdown {
  top: 0;
  left: 0;

  &:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $border;
    border-top: 0;
    border-bottom-color: $border;
    position: absolute;
  }

  &:after {
    content: "";
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $border;
    border-top: 0;
    position: absolute;
  }
}

.datepicker {
  .prev,
  .next {
    background: $primary-01;
    border-radius: 7px;
    text-align: center;
    font-size: 15px;
  }
}

.datepicker-dropdown {
  &.datepicker-orient-left {
    &:before {
      left: 6px;
    }

    &:after {
      left: 7px;
    }
  }

  &.datepicker-orient-right {
    &:before {
      right: 6px;
    }

    &:after {
      right: 7px;
    }
  }

  &.datepicker-orient-bottom {
    &:before {
      top: -7px;
    }

    &:after {
      top: -6px;
    }
  }

  &.datepicker-orient-top {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid $border;
    }

    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid $border;
    }
  }
}

.datepicker {
  table {
    padding: 0px;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-collapse: inherit;
  }

  td {
    text-align: center;
    padding: 6px 9px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: none;
    font-size: 12px;
    font-weight: 400;
    color: #070510;
    border-radius: 4px;
  }

  .datepicker-switch {
    text-align: center;
    color: #313e6a !important;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: inherit !important;

    &:hover {
      color: #3b4863 !important;
      background: transparent !important;
    }
  }

  .prev:hover,
  .next:hover,
  tfoot tr th:hover {
    color: #3b4863 !important;
    background: transparent !important;
  }

  th {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 6px 9px;
    color: #3b4863;
  }
}

.table-striped .datepicker table tr {
  td,
  th {
    background-color: transparent;
  }
}

.datepicker {
  table tr td {
    &.day {
      &:hover,
      &.focused {
        background: rgb(255, 255, 255, 0.1);
        cursor: pointer;
      }
    }

    &.old,
    &.new {
      opacity: 5;
    }

    &.disabled {
      background: none;
      color: #3b4863;
      cursor: default;

      &:hover {
        background: none;
        color: #3b4863;
        cursor: default;
      }
    }

    &.highlighted {
      background: #d9edf7;
      border-radius: 0;
    }

    &.today {
      background-color: #fde19a;
      background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
      background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
      border-color: #fdf59a #fdf59a #fbed50;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: #7e7da0;

      &:hover {
        background-color: #fde19a;
        background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: #fdf59a #fdf59a #fbed50;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #7e7da0;
      }

      &.disabled {
        background-color: #fde19a;
        background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: #fdf59a #fdf59a #fbed50;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #7e7da0;

        &:hover {
          background-color: #fde19a;
          background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
          background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
          border-color: #fdf59a #fdf59a #fbed50;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #7e7da0;
        }
      }

      &:hover,
      &.disabled:hover,
      &:active,
      &:hover:active {
        background-color: #fdf59a;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #fdf59a;
        }
      }

      &.active,
      &:hover.active {
        background-color: #fdf59a;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #fdf59a;
        }

        background-color: #fdf59a;
      }

      &:hover.disabled {
        background-color: #fdf59a;
      }

      &.disabled {
        &.disabled,
        &:hover.disabled {
          background-color: #fdf59a;
        }
      }

      &[disabled],
      &:hover[disabled] {
        background-color: #fdf59a;
      }

      &.disabled {
        &[disabled],
        &:hover[disabled] {
          background-color: #fdf59a;
        }
      }

      &:active,
      &:hover:active {
        background-color: #fbf069 \9;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #fbf069 \9;
        }
      }

      &.active,
      &:hover.active {
        background-color: #fbf069 \9;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #fbf069 \9;
        }
      }

      &:hover:hover {
        color: #000;
      }

      &.active:hover {
        color: #fff;
      }
    }

    &.range {
      background: #d6dcec;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;

      &:hover {
        background: #d6dcec;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }

      &.disabled {
        background: #d6dcec;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        &:hover {
          background: #d6dcec;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }

      &.today {
        background-color: #f3d17a;
        background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
        background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
        border-color: #f3e97a #f3e97a #edde34;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        &:hover {
          background-color: #f3d17a;
          background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
          background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
          border-color: #f3e97a #f3e97a #edde34;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }

        &.disabled {
          background-color: #f3d17a;
          background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
          background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
          border-color: #f3e97a #f3e97a #edde34;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;

          &:hover {
            background-color: #f3d17a;
            background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
            background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
            border-color: #f3e97a #f3e97a #edde34;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
          }
        }

        &:hover,
        &.disabled:hover,
        &:active,
        &:hover:active {
          background-color: #f3e97a;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: #f3e97a;
          }
        }

        &.active,
        &:hover.active {
          background-color: #f3e97a;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: #f3e97a;
          }

          background-color: #f3e97a;
        }

        &:hover.disabled {
          background-color: #f3e97a;
        }

        &.disabled {
          &.disabled,
          &:hover.disabled {
            background-color: #f3e97a;
          }
        }

        &[disabled],
        &:hover[disabled] {
          background-color: #f3e97a;
        }

        &.disabled {
          &[disabled],
          &:hover[disabled] {
            background-color: #f3e97a;
          }
        }

        &:active,
        &:hover:active {
          background-color: #efe24b \9;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: #efe24b \9;
          }
        }

        &.active,
        &:hover.active {
          background-color: #efe24b \9;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: #efe24b \9;
          }
        }
      }
    }

    &.selected {
      background-color: #9e9e9e;
      background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
      background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: linear-gradient(to bottom, #b3b3b3, #808080);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
      border-color: #808080 #808080 #595959;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }

      &.disabled {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: #9e9e9e;
          background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
          background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: linear-gradient(to bottom, #b3b3b3, #808080);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
          border-color: #808080 #808080 #595959;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }

      &:hover,
      &.disabled:hover,
      &:active,
      &:hover:active {
        background-color: #808080;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #808080;
        }
      }

      &.active,
      &:hover.active {
        background-color: #808080;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #808080;
        }

        background-color: #808080;
      }

      &:hover.disabled {
        background-color: #808080;
      }

      &.disabled {
        &.disabled,
        &:hover.disabled {
          background-color: #808080;
        }
      }

      &[disabled],
      &:hover[disabled] {
        background-color: #808080;
      }

      &.disabled {
        &[disabled],
        &:hover[disabled] {
          background-color: #808080;
        }
      }

      &:active,
      &:hover:active {
        background-color: #666666 \9;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #666666 \9;
        }
      }

      &.active,
      &:hover.active {
        background-color: #666666 \9;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #666666 \9;
        }
      }
    }

    &.active {
      background-color: #5965f9;
      background-image: -moz-linear-gradient(to bottom, #5965f9, #5965f9);
      background-image: -ms-linear-gradient(to bottom, #5965f9, #5965f9);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7673e6), to(#7673e6));
      background-image: -webkit-linear-gradient(to bottom, #5965f9, #5965f9);
      background-image: -o-linear-gradient(to bottom, #5965f9, #5965f9);
      background-image: linear-gradient(to bottom, #5965f9, #5965f9);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7673e6', endColorstr='#7673e6', GradientType=0);
      border-color: #5965f9 #7673e6 #002a80;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: #5965f9;
        background-image: -moz-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: -ms-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7673e6), to(#7673e6));
        background-image: -webkit-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: -o-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: linear-gradient(to bottom, #5965f9, #5965f9);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7673e6', endColorstr='#7673e6', GradientType=0);
        border-color: #5965f9 #7673e6 #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }

      &.disabled {
        background-color: #5965f9;
        background-image: -moz-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: -ms-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7673e6), to(#7673e6));
        background-image: -webkit-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: -o-linear-gradient(to bottom, #5965f9, #5965f9);
        background-image: linear-gradient(to bottom, #5965f9, #5965f9);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7673e6', endColorstr='#7673e6', GradientType=0);
        border-color: #5965f9 #7673e6 #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: #5965f9;
          background-image: -moz-linear-gradient(to bottom, #5965f9, #5965f9);
          background-image: -ms-linear-gradient(to bottom, #5965f9, #5965f9);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7673e6), to(#7673e6));
          background-image: -webkit-linear-gradient(to bottom, #5965f9, #5965f9);
          background-image: -o-linear-gradient(to bottom, #5965f9, #5965f9);
          background-image: linear-gradient(to bottom, #5965f9, #5965f9);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7673e6', endColorstr='#7673e6', GradientType=0);
          border-color: #5965f9 #7673e6 #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }

      &:hover,
      &.disabled:hover,
      &:active,
      &:hover:active {
        background-color: #5965f9;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #5965f9;
        }
      }

      &.active,
      &:hover.active {
        background-color: #5965f9;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #5965f9;
        }

        background-color: #5965f9;
      }

      &:hover.disabled {
        background-color: #5965f9;
      }

      &.disabled {
        &.disabled,
        &:hover.disabled {
          background-color: #5965f9;
        }
      }

      &[disabled],
      &:hover[disabled] {
        background-color: #5965f9;
      }

      &.disabled {
        &[disabled],
        &:hover[disabled] {
          background-color: #5965f9;
        }
      }

      &:active,
      &:hover:active {
        background-color: #003399 \9;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #003399 \9;
        }
      }

      &.active,
      &:hover.active {
        background-color: #003399 \9;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #003399 \9;
        }
      }
    }

    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;

      &:hover,
      &.focused {
        background: $primary-005 !important;
        color: $primary-1 !important;
      }

      &.disabled {
        background: none;
        color: #3b4863;
        cursor: default;

        &:hover {
          background: none;
          color: #3b4863;
          cursor: default;
        }
      }

      &.active {
        background-color: $primary-1;
        color: #fff;

        &:hover {
          background-color: $primary-1;
          color: #fff;
        }

        &.disabled {
          background-color: $primary-1;
          color: #fff;

          &:hover {
            background-color: $primary-1;
            color: #fff;
          }
        }

        &:hover,
        &.disabled:hover,
        &:active,
        &:hover:active {
          background-color: $primary-1;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: $primary-1;
          }
        }

        &.active,
        &:hover.active {
          background-color: $primary-1;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: $primary-1;
          }

          background-color: $primary-1;
        }

        &:hover.disabled {
          background-color: $primary-1;
        }

        &.disabled {
          &.disabled,
          &:hover.disabled {
            background-color: $primary-1;
          }
        }

        &[disabled],
        &:hover[disabled] {
          background-color: $primary-1;
        }

        &.disabled {
          &[disabled],
          &:hover[disabled] {
            background-color: $primary-1;
          }
        }

        &:active,
        &:hover:active {
          background-color: $primary-1;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: $primary-1;
          }
        }

        &.active,
        &:hover.active {
          background-color: $primary-1;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: $primary-1;
          }
        }
      }

      &.old,
      &.new {
        color: #3b4863;
      }
    }
  }

  .datepicker-switch {
    width: 145px;
    cursor: pointer;
    color: #3b4863;
    opacity: 5;
  }

  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
    color: #3b4863;
    opacity: 5;
  }

  .datepicker-switch:hover,
  .prev:hover,
  .next:hover,
  tfoot tr th:hover {
    background: rgb(255, 255, 255, 0.1);
    color: #3b4863;
  }

  .prev.disabled,
  .next.disabled {
    visibility: hidden;
  }

  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange {
  input {
    text-align: center;

    &:first-child {
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;
    }
  }

  .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #d6dcec;
    border: 1px solid #7f88a5;
    margin-left: -5px;
    margin-right: -5px;
  }
}

.datepicker-days .table-condensed .active day {
  background-color: #5965f9;
  color: #fff;
}

.datepicker {
  .datepicker-switch,
  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
    color: $primary-1;
    opacity: 5;
  }
}

.dark-theme .datepicker .datepicker-switch {
  cursor: pointer;
  opacity: 5;
}

.datepicker {
  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
    opacity: 5;
  }
}

.dark-theme .datepicker {
  .datepicker-switch {
    color: #ffffff !important;
  }

  th {
    color: #ffffff;
  }

  background-color: #191f3a;
  box-shadow: 0 0 24px rgb(20 28 43 / 60%);
  border: 1px solid rgba(255, 255, 255, 0.1);

  td {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #191f3a;
    color: rgba(255, 255, 255, 0.4);
  }

  table tr td.old {
    opacity: 5;
    color: #7f88a5;
  }
}

.datepicker table tr td.new {
  opacity: 5;
  color: #7f88a5;
}

.dark-theme .datepicker {
  .datepicker-calendar td a:hover,
  table tr td.day:hover {
    background-color: #5965f9;
    color: #fff;
  }
}

.datepicker table tr td.day.focused {
  background-color: #5965f9;
  color: #fff;
}

.dark-theme {
  .datepicker-dropdown {
    &:before {
      content: "";
      display: inline-block;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #191f3a;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }

    &:after {
      content: "";
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 7px solid #191f3a;
      border-top: 0;
      position: absolute;
    }
  }

  .datepicker table tr td span {
    &:hover,
    &.focused {
      background: #292f4b !important;
    }
  }

  .datepicker-dropdown.datepicker-orient-top {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #191f3a;
    }

    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #191f3a;
    }
  }

  .datepicker table tr td span.old {
    color: #7f88a5;
  }
}

.datepicker table tr td span.new {
  color: #7f88a5;
}

.dark-theme .datepicker {
  .datepicker-months td,
  .datepicker-years td {
    border: none;
  }
}
