blockquote {
	margin: 0 0 1rem;
}
.blockquote {
	margin-block-end: 1rem;
	font-size: 1.171875rem;
}
.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #868e96;
	&::before {
		content: "\2014 \00A0";
	}
}
p, ul, ol {
	margin-block-end: 1em;
}
blockquote {
	margin-block-end: 1em;
	font-style: italic;
	color: $default-color;
	padding-inline-start: 2rem;
	border-inline-start: 2px solid $border;
	font-style: inherit;
	p {
		margin-block-end: 1rem;
		font-size: 16px;
	}
	cite {
		&:before {
			content: '— ';
		}
	}
}
.blockquote-reverse {
    border-inline-start: 0;
    border-inline-end: 2px solid $border;
    text-align: end;
	padding-inline-end: 2rem;
	padding-inline-start: 0;
}