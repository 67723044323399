.widgets-cards .wrp {
	&.icon-circle {
		width: 73px;
		height: 73px;
		border-radius: 50px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 0;
		margin-inline-end: 15px;
		i {
			font-size: 1.6rem;
			color: $white;
		}
	}
	p {
		margin-block-end: 0;
		line-height: 1;
		margin-block-end: 10px;
		&:first-child {
			font-weight: 600;
			font-size: 25px;
		}
	}
}
/**widgets*/

.widgets {
	font-size: 35px;
	padding: 20px;
}
.widgets-cards .widgets-cards-data {
	margin-block-start: 8px;
	.wrp p:first-child {
		font-size: 20px;
		font-weight: 500;
	}
}