.float-start {
	float: $float-left !important;
}
.float-end {
	float: $float-right !important;
}
.float-none {
	float: none !important;
}
@media (min-width: 576px) {
	.float-sm-start {
		float: $float-left !important;
	}
	.float-sm-end {
		float: $float-right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}
@media (min-width: 768px) {
	.float-md-start {
		float: $float-left !important;
	}
	.float-md-end {
		float: $float-right !important;
	}
	.float-md-none {
		float: none !important;
	}
}
@media (min-width: 992px) {
	.float-lg-start {
		float: $float-left !important;
	}
	.float-lg-end {
		float: $float-right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}
@media (min-width: 1280px) {
	.float-xl-start {
		float: $float-left !important;
	}
	.float-xl-end {
		float: $float-right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}
.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}