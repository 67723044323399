.page {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
}

body.fixed-header .page {
    padding-block-start: 4.5rem;
}

.page-main {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.page-content {
    margin: .75rem 0;
}

@media (min-width: 768px) {
    .page-content {
        margin: 1.5rem 0;
    }
}

.example .pagination {
    margin-block-end: 0;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-inline-start: 0;
    list-style: none;
    border-radius: 3px;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-inline-start: -1px;
    line-height: 1.25;
    background-color: $white;
    border: 1px solid #dadde1;
    color: $default-color;
    &:hover {
        z-index: 2;
        text-decoration: none;
        background-color: $primary-005;
    }
    &:focus {
        z-index: 2;
        outline: 0;
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

.page-item {
    &:first-child .page-link {
        margin-inline-start: 0;
        border-start-start-radius: 5px;
        border-end-start-radius: 5px;
    }
    &:last-child .page-link {
        border-start-end-radius: 5px;
        border-end-end-radius: 5px;
    }
    &.active .page-link {
        z-index: 1;
        color: $white;
    }
    &.disabled .page-link {
        color: #ced4da;
        pointer-events: none;
        cursor: auto;
        background-color: $white;
        border-color: #dadde1;
    }
}

.pagination-lg {
    .page-link {
        padding: 0.75rem 1.3rem;
        font-size: 1.125rem;
        line-height: 1.5;
    }
    .page-item {
        &:first-child .page-link {
            border-start-start-radius: 7px;
            border-end-start-radius: 7px;
        }
        &:last-child .page-link {
            border-start-end-radius: 7px;
            border-end-end-radius: 7px;
        }
    }
}

.pagination-sm {
    .page-link {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
    }
    .page-item {
        &:first-child .page-link {
            border-start-start-radius: 7px;
            border-end-start-radius: 7px;
        }
        &:last-child .page-link {
            border-start-end-radius: 7px;
            border-end-end-radius: 7px;
        }
    }
}

.page-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.5rem 0rem 1.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    position: relative;
    min-height: 50px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.page-title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin-block-end: 0.2rem;
}

.page-options {
    margin-inline-start: auto;
}

.page-header .breadcrumb {
    font-size: 13px;
    padding-inline-start: 0 !important;
}
@media (max-width: 400px) {
    .pagination-lg .page-link {
        padding: 0.75rem 1rem;
    }
}