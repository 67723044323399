.iti {
	position: relative;
	display: inline-block;
	margin-block-end: 4px;
}

.iti__hide {
	display: none;
}

.iti__v-hide {
	visibility: hidden;
}

.iti input {
	position: relative;
	z-index: 0;
	margin-block-start: 1px !important;
	margin-block-end: 0 !important;
	padding-inline-end: 36px;
	margin-inline-end: 0;
	height: 36px;
	border: 1px solid #ecf0fa;
	border-radius: 4px;

	&[type="text"],
	&[type="tel"] {
		position: relative;
		z-index: 0;
		margin-block-start: 1px !important;
		margin-block-end: 0 !important;
		padding-inline-end: 36px;
		margin-inline-end: 0;
		height: 36px;
		border: 1px solid #ecf0fa;
		border-radius: 4px;
	}

	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder {
		color: #bcc0e2;
	}
}

.iti__flag-container {
	position: absolute;
	inset-block-start: 0;
	inset-block-end: 0;
	inset-inline-end: 0;
	padding: 1px;
}

.iti__selected-flag {
	z-index: 1;
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;
	padding-block-start: 0px;
    padding-inline-end: 6px;
    padding-block-end: 0px;
    padding-inline-start: 8px;
	border-inline-end: 1px solid #ecf0fa;
}

.iti__arrow {
	margin-inline-start: 6px;
	width: 0;
	height: 0;
	border-inline-start: 3px solid transparent;
	border-inline-end: 3px solid transparent;
	border-block-start: 4px solid #b6b9ce;
}

.iti__arrow--up {
	border-block-start: none;
	border-block-end: 4px solid #b6b9ce;
}

.iti__country-list {
	position: absolute;
	z-index: 2;
	list-style: none;
	text-align: start;
	padding: 0;
	margin-block-start: 0px;
    margin-inline-end: 0px;
    margin-block-end: 0px;
    margin-inline-start: -1px;
	background-color: white;
	border: 1px solid #eaeaec;
	border-radius: 4px;
	white-space: nowrap !important;
	font-size: 13px;
	max-height: 200px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
}

.iti__country-list--dropup {
	inset-block-end: 100%;
	margin-block-end: -1px;
}

@media (max-width: 500px) {
	.iti__country-list {
		white-space: normal;
	}
}

.iti__flag-box {
	display: inline-block;
	width: 20px;
}

.iti__divider {
	padding-block-end: 5px;
	margin-block-end: 5px;
	border-block-end: 1px solid #e7eaf3;
}

.iti__country {
	padding: 0px 15px;
	outline: none;
	height: 36px;
	line-height: 36px;
}

.iti__dial-code {
	color: #9f97b3;
	font-weight: 500;
	font-size: 12px;
}

.iti__country.iti__highlight {
	background-color: #f4f5fb;
}

.iti__flag-box,
.iti__country-name,
.iti__dial-code {
	vertical-align: middle;
}

.iti__flag-box,
.iti__country-name {
	margin-inline-end: 6px;
}

.iti--allow-dropdown input {
	padding-inline-end: 6px;
	padding-inline-start: 52px;
	margin-inline-start: 0;

	&[type="text"],
	&[type="tel"] {
		padding-inline-end: 6px;
		padding-inline-start: 52px;
		margin-inline-start: 0;
	}
}

.iti--separate-dial-code input {
	padding-inline-end: 6px;
	padding-inline-start: 52px;
	margin-inline-start: 0;

	&[type="text"],
	&[type="tel"] {
		padding-inline-end: 6px;
		padding-inline-start: 52px;
		margin-inline-start: 0;
	}
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
	inset-inline-end: auto;
	inset-inline-start: 0;
}

.iti--allow-dropdown {
	.iti__flag-container:hover {
		cursor: pointer;

		.iti__selected-flag {
			background-color: #f4f5fb;
		}
	}

	input {
		&[disabled] + .iti__flag-container:hover,
		&[readonly] + .iti__flag-container:hover {
			cursor: default;
		}

		&[disabled] + .iti__flag-container:hover .iti__selected-flag,
		&[readonly] + .iti__flag-container:hover .iti__selected-flag {
			background-color: transparent;
		}
	}
}

.iti--separate-dial-code {
	.iti__selected-flag {
		background-color: #f4f5fb;
	}

	.iti__selected-dial-code {
		margin-inline-start: 6px;
	}
}

.iti--container {
	position: absolute;
	inset-block-start: -1000px;
	inset-inline-start: -1000px;
	z-index: 1060;
	padding: 1px;

	&:hover {
		cursor: pointer;
	}
}

.iti-mobile {
	.iti--container {
		inset-block-start: 30px;
		inset-block-end: 30px;
		inset-inline-start: 30px;
		inset-inline-end: 30px;
		position: fixed;
	}

	.iti__country-list {
		max-height: 100%;
		width: 100%;
	}

	.iti__country {
		padding: 10px 10px;
		line-height: 1.5em;
	}
}

.iti__flag {
	width: 20px;

	&.iti__be {
		width: 18px;
	}

	&.iti__ch {
		width: 15px;
	}

	&.iti__mc {
		width: 19px;
	}

	&.iti__ne {
		width: 18px;
	}

	&.iti__np {
		width: 13px;
	}

	&.iti__va {
		width: 15px;
	}

	&.iti__ac {
		height: 10px;
		background-position: 0px 0px;
	}

	&.iti__ad {
		height: 14px;
		background-position: -22px 0px;
	}

	&.iti__ae {
		height: 10px;
		background-position: -44px 0px;
	}

	&.iti__af {
		height: 14px;
		background-position: -66px 0px;
	}

	&.iti__ag {
		height: 14px;
		background-position: -88px 0px;
	}

	&.iti__ai {
		height: 10px;
		background-position: -110px 0px;
	}

	&.iti__al {
		height: 15px;
		background-position: -132px 0px;
	}

	&.iti__am {
		height: 10px;
		background-position: -154px 0px;
	}

	&.iti__ao {
		height: 14px;
		background-position: -176px 0px;
	}

	&.iti__aq {
		height: 14px;
		background-position: -198px 0px;
	}

	&.iti__ar {
		height: 13px;
		background-position: -220px 0px;
	}

	&.iti__as {
		height: 10px;
		background-position: -242px 0px;
	}

	&.iti__at {
		height: 14px;
		background-position: -264px 0px;
	}

	&.iti__au {
		height: 10px;
		background-position: -286px 0px;
	}

	&.iti__aw {
		height: 14px;
		background-position: -308px 0px;
	}

	&.iti__ax {
		height: 13px;
		background-position: -330px 0px;
	}

	&.iti__az {
		height: 10px;
		background-position: -352px 0px;
	}

	&.iti__ba {
		height: 10px;
		background-position: -374px 0px;
	}

	&.iti__bb {
		height: 14px;
		background-position: -396px 0px;
	}

	&.iti__bd {
		height: 12px;
		background-position: -418px 0px;
	}

	&.iti__be {
		height: 15px;
		background-position: -440px 0px;
	}

	&.iti__bf {
		height: 14px;
		background-position: -460px 0px;
	}

	&.iti__bg {
		height: 12px;
		background-position: -482px 0px;
	}

	&.iti__bh {
		height: 12px;
		background-position: -504px 0px;
	}

	&.iti__bi {
		height: 12px;
		background-position: -526px 0px;
	}

	&.iti__bj {
		height: 14px;
		background-position: -548px 0px;
	}

	&.iti__bl {
		height: 14px;
		background-position: -570px 0px;
	}

	&.iti__bm {
		height: 10px;
		background-position: -592px 0px;
	}

	&.iti__bn {
		height: 10px;
		background-position: -614px 0px;
	}

	&.iti__bo {
		height: 14px;
		background-position: -636px 0px;
	}

	&.iti__bq {
		height: 14px;
		background-position: -658px 0px;
	}

	&.iti__br {
		height: 14px;
		background-position: -680px 0px;
	}

	&.iti__bs {
		height: 10px;
		background-position: -702px 0px;
	}

	&.iti__bt {
		height: 14px;
		background-position: -724px 0px;
	}

	&.iti__bv {
		height: 15px;
		background-position: -746px 0px;
	}

	&.iti__bw {
		height: 14px;
		background-position: -768px 0px;
	}

	&.iti__by {
		height: 10px;
		background-position: -790px 0px;
	}

	&.iti__bz {
		height: 14px;
		background-position: -812px 0px;
	}

	&.iti__ca {
		height: 10px;
		background-position: -834px 0px;
	}

	&.iti__cc {
		height: 10px;
		background-position: -856px 0px;
	}

	&.iti__cd {
		height: 15px;
		background-position: -878px 0px;
	}

	&.iti__cf {
		height: 14px;
		background-position: -900px 0px;
	}

	&.iti__cg {
		height: 14px;
		background-position: -922px 0px;
	}

	&.iti__ch {
		height: 15px;
		background-position: -944px 0px;
	}

	&.iti__ci {
		height: 14px;
		background-position: -961px 0px;
	}

	&.iti__ck {
		height: 10px;
		background-position: -983px 0px;
	}

	&.iti__cl {
		height: 14px;
		background-position: -1005px 0px;
	}

	&.iti__cm {
		height: 14px;
		background-position: -1027px 0px;
	}

	&.iti__cn {
		height: 14px;
		background-position: -1049px 0px;
	}

	&.iti__co {
		height: 14px;
		background-position: -1071px 0px;
	}

	&.iti__cp {
		height: 14px;
		background-position: -1093px 0px;
	}

	&.iti__cr {
		height: 12px;
		background-position: -1115px 0px;
	}

	&.iti__cu {
		height: 10px;
		background-position: -1137px 0px;
	}

	&.iti__cv {
		height: 12px;
		background-position: -1159px 0px;
	}

	&.iti__cw {
		height: 14px;
		background-position: -1181px 0px;
	}

	&.iti__cx {
		height: 10px;
		background-position: -1203px 0px;
	}

	&.iti__cy {
		height: 14px;
		background-position: -1225px 0px;
	}

	&.iti__cz {
		height: 14px;
		background-position: -1247px 0px;
	}

	&.iti__de {
		height: 12px;
		background-position: -1269px 0px;
	}

	&.iti__dg {
		height: 10px;
		background-position: -1291px 0px;
	}

	&.iti__dj {
		height: 14px;
		background-position: -1313px 0px;
	}

	&.iti__dk {
		height: 15px;
		background-position: -1335px 0px;
	}

	&.iti__dm {
		height: 10px;
		background-position: -1357px 0px;
	}

	&.iti__do {
		height: 14px;
		background-position: -1379px 0px;
	}

	&.iti__dz {
		height: 14px;
		background-position: -1401px 0px;
	}

	&.iti__ea {
		height: 14px;
		background-position: -1423px 0px;
	}

	&.iti__ec {
		height: 14px;
		background-position: -1445px 0px;
	}

	&.iti__ee {
		height: 13px;
		background-position: -1467px 0px;
	}

	&.iti__eg {
		height: 14px;
		background-position: -1489px 0px;
	}

	&.iti__eh {
		height: 10px;
		background-position: -1511px 0px;
	}

	&.iti__er {
		height: 10px;
		background-position: -1533px 0px;
	}

	&.iti__es {
		height: 14px;
		background-position: -1555px 0px;
	}

	&.iti__et {
		height: 10px;
		background-position: -1577px 0px;
	}

	&.iti__eu {
		height: 14px;
		background-position: -1599px 0px;
	}

	&.iti__fi {
		height: 12px;
		background-position: -1621px 0px;
	}

	&.iti__fj {
		height: 10px;
		background-position: -1643px 0px;
	}

	&.iti__fk {
		height: 10px;
		background-position: -1665px 0px;
	}

	&.iti__fm {
		height: 11px;
		background-position: -1687px 0px;
	}

	&.iti__fo {
		height: 15px;
		background-position: -1709px 0px;
	}

	&.iti__fr {
		height: 14px;
		background-position: -1731px 0px;
	}

	&.iti__ga {
		height: 15px;
		background-position: -1753px 0px;
	}

	&.iti__gb {
		height: 10px;
		background-position: -1775px 0px;
	}

	&.iti__gd {
		height: 12px;
		background-position: -1797px 0px;
	}

	&.iti__ge {
		height: 14px;
		background-position: -1819px 0px;
	}

	&.iti__gf {
		height: 14px;
		background-position: -1841px 0px;
	}

	&.iti__gg {
		height: 14px;
		background-position: -1863px 0px;
	}

	&.iti__gh {
		height: 14px;
		background-position: -1885px 0px;
	}

	&.iti__gi {
		height: 10px;
		background-position: -1907px 0px;
	}

	&.iti__gl {
		height: 14px;
		background-position: -1929px 0px;
	}

	&.iti__gm {
		height: 14px;
		background-position: -1951px 0px;
	}

	&.iti__gn {
		height: 14px;
		background-position: -1973px 0px;
	}

	&.iti__gp {
		height: 14px;
		background-position: -1995px 0px;
	}

	&.iti__gq {
		height: 14px;
		background-position: -2017px 0px;
	}

	&.iti__gr {
		height: 14px;
		background-position: -2039px 0px;
	}

	&.iti__gs {
		height: 10px;
		background-position: -2061px 0px;
	}

	&.iti__gt {
		height: 13px;
		background-position: -2083px 0px;
	}

	&.iti__gu {
		height: 11px;
		background-position: -2105px 0px;
	}

	&.iti__gw {
		height: 10px;
		background-position: -2127px 0px;
	}

	&.iti__gy {
		height: 12px;
		background-position: -2149px 0px;
	}

	&.iti__hk {
		height: 14px;
		background-position: -2171px 0px;
	}

	&.iti__hm {
		height: 10px;
		background-position: -2193px 0px;
	}

	&.iti__hn {
		height: 10px;
		background-position: -2215px 0px;
	}

	&.iti__hr {
		height: 10px;
		background-position: -2237px 0px;
	}

	&.iti__ht {
		height: 12px;
		background-position: -2259px 0px;
	}

	&.iti__hu {
		height: 10px;
		background-position: -2281px 0px;
	}

	&.iti__ic {
		height: 14px;
		background-position: -2303px 0px;
	}

	&.iti__id {
		height: 14px;
		background-position: -2325px 0px;
	}

	&.iti__ie {
		height: 10px;
		background-position: -2347px 0px;
	}

	&.iti__il {
		height: 15px;
		background-position: -2369px 0px;
	}

	&.iti__im {
		height: 10px;
		background-position: -2391px 0px;
	}

	&.iti__in {
		height: 14px;
		background-position: -2413px 0px;
	}

	&.iti__io {
		height: 10px;
		background-position: -2435px 0px;
	}

	&.iti__iq {
		height: 14px;
		background-position: -2457px 0px;
	}

	&.iti__ir {
		height: 12px;
		background-position: -2479px 0px;
	}

	&.iti__is {
		height: 15px;
		background-position: -2501px 0px;
	}

	&.iti__it {
		height: 14px;
		background-position: -2523px 0px;
	}

	&.iti__je {
		height: 12px;
		background-position: -2545px 0px;
	}

	&.iti__jm {
		height: 10px;
		background-position: -2567px 0px;
	}

	&.iti__jo {
		height: 10px;
		background-position: -2589px 0px;
	}

	&.iti__jp {
		height: 14px;
		background-position: -2611px 0px;
	}

	&.iti__ke {
		height: 14px;
		background-position: -2633px 0px;
	}

	&.iti__kg {
		height: 12px;
		background-position: -2655px 0px;
	}

	&.iti__kh {
		height: 13px;
		background-position: -2677px 0px;
	}

	&.iti__ki {
		height: 10px;
		background-position: -2699px 0px;
	}

	&.iti__km {
		height: 12px;
		background-position: -2721px 0px;
	}

	&.iti__kn {
		height: 14px;
		background-position: -2743px 0px;
	}

	&.iti__kp {
		height: 10px;
		background-position: -2765px 0px;
	}

	&.iti__kr {
		height: 14px;
		background-position: -2787px 0px;
	}

	&.iti__kw {
		height: 10px;
		background-position: -2809px 0px;
	}

	&.iti__ky {
		height: 10px;
		background-position: -2831px 0px;
	}

	&.iti__kz {
		height: 10px;
		background-position: -2853px 0px;
	}

	&.iti__la {
		height: 14px;
		background-position: -2875px 0px;
	}

	&.iti__lb {
		height: 14px;
		background-position: -2897px 0px;
	}

	&.iti__lc {
		height: 10px;
		background-position: -2919px 0px;
	}

	&.iti__li {
		height: 12px;
		background-position: -2941px 0px;
	}

	&.iti__lk {
		height: 10px;
		background-position: -2963px 0px;
	}

	&.iti__lr {
		height: 11px;
		background-position: -2985px 0px;
	}

	&.iti__ls {
		height: 14px;
		background-position: -3007px 0px;
	}

	&.iti__lt {
		height: 12px;
		background-position: -3029px 0px;
	}

	&.iti__lu {
		height: 12px;
		background-position: -3051px 0px;
	}

	&.iti__lv {
		height: 10px;
		background-position: -3073px 0px;
	}

	&.iti__ly {
		height: 10px;
		background-position: -3095px 0px;
	}

	&.iti__ma {
		height: 14px;
		background-position: -3117px 0px;
	}

	&.iti__mc {
		height: 15px;
		background-position: -3139px 0px;
	}

	&.iti__md {
		height: 10px;
		background-position: -3160px 0px;
	}

	&.iti__me {
		height: 10px;
		background-position: -3182px 0px;
	}

	&.iti__mf {
		height: 14px;
		background-position: -3204px 0px;
	}

	&.iti__mg {
		height: 14px;
		background-position: -3226px 0px;
	}

	&.iti__mh {
		height: 11px;
		background-position: -3248px 0px;
	}

	&.iti__mk {
		height: 10px;
		background-position: -3270px 0px;
	}

	&.iti__ml {
		height: 14px;
		background-position: -3292px 0px;
	}

	&.iti__mm {
		height: 14px;
		background-position: -3314px 0px;
	}

	&.iti__mn {
		height: 10px;
		background-position: -3336px 0px;
	}

	&.iti__mo {
		height: 14px;
		background-position: -3358px 0px;
	}

	&.iti__mp {
		height: 10px;
		background-position: -3380px 0px;
	}

	&.iti__mq {
		height: 14px;
		background-position: -3402px 0px;
	}

	&.iti__mr {
		height: 14px;
		background-position: -3424px 0px;
	}

	&.iti__ms {
		height: 10px;
		background-position: -3446px 0px;
	}

	&.iti__mt {
		height: 14px;
		background-position: -3468px 0px;
	}

	&.iti__mu {
		height: 14px;
		background-position: -3490px 0px;
	}

	&.iti__mv {
		height: 14px;
		background-position: -3512px 0px;
	}

	&.iti__mw {
		height: 14px;
		background-position: -3534px 0px;
	}

	&.iti__mx {
		height: 12px;
		background-position: -3556px 0px;
	}

	&.iti__my {
		height: 10px;
		background-position: -3578px 0px;
	}

	&.iti__mz {
		height: 14px;
		background-position: -3600px 0px;
	}

	&.iti__na {
		height: 14px;
		background-position: -3622px 0px;
	}

	&.iti__nc {
		height: 10px;
		background-position: -3644px 0px;
	}

	&.iti__ne {
		height: 15px;
		background-position: -3666px 0px;
	}

	&.iti__nf {
		height: 10px;
		background-position: -3686px 0px;
	}

	&.iti__ng {
		height: 10px;
		background-position: -3708px 0px;
	}

	&.iti__ni {
		height: 12px;
		background-position: -3730px 0px;
	}

	&.iti__nl {
		height: 14px;
		background-position: -3752px 0px;
	}

	&.iti__no {
		height: 15px;
		background-position: -3774px 0px;
	}

	&.iti__np {
		height: 15px;
		background-position: -3796px 0px;
	}

	&.iti__nr {
		height: 10px;
		background-position: -3811px 0px;
	}

	&.iti__nu {
		height: 10px;
		background-position: -3833px 0px;
	}

	&.iti__nz {
		height: 10px;
		background-position: -3855px 0px;
	}

	&.iti__om {
		height: 10px;
		background-position: -3877px 0px;
	}

	&.iti__pa {
		height: 14px;
		background-position: -3899px 0px;
	}

	&.iti__pe {
		height: 14px;
		background-position: -3921px 0px;
	}

	&.iti__pf {
		height: 14px;
		background-position: -3943px 0px;
	}

	&.iti__pg {
		height: 15px;
		background-position: -3965px 0px;
	}

	&.iti__ph {
		height: 10px;
		background-position: -3987px 0px;
	}

	&.iti__pk {
		height: 14px;
		background-position: -4009px 0px;
	}

	&.iti__pl {
		height: 13px;
		background-position: -4031px 0px;
	}

	&.iti__pm {
		height: 14px;
		background-position: -4053px 0px;
	}

	&.iti__pn {
		height: 10px;
		background-position: -4075px 0px;
	}

	&.iti__pr {
		height: 14px;
		background-position: -4097px 0px;
	}

	&.iti__ps {
		height: 10px;
		background-position: -4119px 0px;
	}

	&.iti__pt {
		height: 14px;
		background-position: -4141px 0px;
	}

	&.iti__pw {
		height: 13px;
		background-position: -4163px 0px;
	}

	&.iti__py {
		height: 11px;
		background-position: -4185px 0px;
	}

	&.iti__qa {
		height: 8px;
		background-position: -4207px 0px;
	}

	&.iti__re {
		height: 14px;
		background-position: -4229px 0px;
	}

	&.iti__ro {
		height: 14px;
		background-position: -4251px 0px;
	}

	&.iti__rs {
		height: 14px;
		background-position: -4273px 0px;
	}

	&.iti__ru {
		height: 14px;
		background-position: -4295px 0px;
	}

	&.iti__rw {
		height: 14px;
		background-position: -4317px 0px;
	}

	&.iti__sa {
		height: 14px;
		background-position: -4339px 0px;
	}

	&.iti__sb {
		height: 10px;
		background-position: -4361px 0px;
	}

	&.iti__sc {
		height: 10px;
		background-position: -4383px 0px;
	}

	&.iti__sd {
		height: 10px;
		background-position: -4405px 0px;
	}

	&.iti__se {
		height: 13px;
		background-position: -4427px 0px;
	}

	&.iti__sg {
		height: 14px;
		background-position: -4449px 0px;
	}

	&.iti__sh {
		height: 10px;
		background-position: -4471px 0px;
	}

	&.iti__si {
		height: 10px;
		background-position: -4493px 0px;
	}

	&.iti__sj {
		height: 15px;
		background-position: -4515px 0px;
	}

	&.iti__sk {
		height: 14px;
		background-position: -4537px 0px;
	}

	&.iti__sl {
		height: 14px;
		background-position: -4559px 0px;
	}

	&.iti__sm {
		height: 15px;
		background-position: -4581px 0px;
	}

	&.iti__sn {
		height: 14px;
		background-position: -4603px 0px;
	}

	&.iti__so {
		height: 14px;
		background-position: -4625px 0px;
	}

	&.iti__sr {
		height: 14px;
		background-position: -4647px 0px;
	}

	&.iti__ss {
		height: 10px;
		background-position: -4669px 0px;
	}

	&.iti__st {
		height: 10px;
		background-position: -4691px 0px;
	}

	&.iti__sv {
		height: 12px;
		background-position: -4713px 0px;
	}

	&.iti__sx {
		height: 14px;
		background-position: -4735px 0px;
	}

	&.iti__sy {
		height: 14px;
		background-position: -4757px 0px;
	}

	&.iti__sz {
		height: 14px;
		background-position: -4779px 0px;
	}

	&.iti__ta {
		height: 10px;
		background-position: -4801px 0px;
	}

	&.iti__tc {
		height: 10px;
		background-position: -4823px 0px;
	}

	&.iti__td {
		height: 14px;
		background-position: -4845px 0px;
	}

	&.iti__tf {
		height: 14px;
		background-position: -4867px 0px;
	}

	&.iti__tg {
		height: 13px;
		background-position: -4889px 0px;
	}

	&.iti__th {
		height: 14px;
		background-position: -4911px 0px;
	}

	&.iti__tj {
		height: 10px;
		background-position: -4933px 0px;
	}

	&.iti__tk {
		height: 10px;
		background-position: -4955px 0px;
	}

	&.iti__tl {
		height: 10px;
		background-position: -4977px 0px;
	}

	&.iti__tm {
		height: 14px;
		background-position: -4999px 0px;
	}

	&.iti__tn {
		height: 14px;
		background-position: -5021px 0px;
	}

	&.iti__to {
		height: 10px;
		background-position: -5043px 0px;
	}

	&.iti__tr {
		height: 14px;
		background-position: -5065px 0px;
	}

	&.iti__tt {
		height: 12px;
		background-position: -5087px 0px;
	}

	&.iti__tv {
		height: 10px;
		background-position: -5109px 0px;
	}

	&.iti__tw {
		height: 14px;
		background-position: -5131px 0px;
	}

	&.iti__tz {
		height: 14px;
		background-position: -5153px 0px;
	}

	&.iti__ua {
		height: 14px;
		background-position: -5175px 0px;
	}

	&.iti__ug {
		height: 14px;
		background-position: -5197px 0px;
	}

	&.iti__um {
		height: 11px;
		background-position: -5219px 0px;
	}

	&.iti__un {
		height: 14px;
		background-position: -5241px 0px;
	}

	&.iti__us {
		height: 11px;
		background-position: -5263px 0px;
	}

	&.iti__uy {
		height: 14px;
		background-position: -5285px 0px;
	}

	&.iti__uz {
		height: 10px;
		background-position: -5307px 0px;
	}

	&.iti__va {
		height: 15px;
		background-position: -5329px 0px;
	}

	&.iti__vc {
		height: 14px;
		background-position: -5346px 0px;
	}

	&.iti__ve {
		height: 14px;
		background-position: -5368px 0px;
	}

	&.iti__vg {
		height: 10px;
		background-position: -5390px 0px;
	}

	&.iti__vi {
		height: 14px;
		background-position: -5412px 0px;
	}

	&.iti__vn {
		height: 14px;
		background-position: -5434px 0px;
	}

	&.iti__vu {
		height: 12px;
		background-position: -5456px 0px;
	}

	&.iti__wf {
		height: 14px;
		background-position: -5478px 0px;
	}

	&.iti__ws {
		height: 10px;
		background-position: -5500px 0px;
	}

	&.iti__xk {
		height: 15px;
		background-position: -5522px 0px;
	}

	&.iti__ye {
		height: 14px;
		background-position: -5544px 0px;
	}

	&.iti__yt {
		height: 14px;
		background-position: -5566px 0px;
	}

	&.iti__za {
		height: 14px;
		background-position: -5588px 0px;
	}

	&.iti__zm {
		height: 14px;
		background-position: -5610px 0px;
	}

	&.iti__zw {
		height: 10px;
		background-position: -5632px 0px;
	}

	height: 15px;
	background-image: url("../plugins/intl-tel-input-master/img/flags.png");
	background-repeat: no-repeat;
	background-color: #f4f5fb;
	background-position: 20px 0;

	&.iti__np {
		background-color: transparent;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti__flag {
		background-size: 5652px 15px;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti__flag {
		background-image: url("../plugins/intl-tel-input-master/img/flags@2x.png");
	}
}

.iti input {
	outline: 0;
}
