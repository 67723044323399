/*-----Pricing tables-----*/

.pricing {
    color: $white;
}

.pricing {
    .list-unstyled li {
        border-block-end: 1px solid $white-1;
        padding: 7px 0;
    }
}

.table> {
    tbody>tr>th {
        font-weight: 500;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
    }
    tfoot>tr> {
        td,
        th {
            font-weight: 500;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
        }
    }
    thead>tr> {
        td,
        th {
            font-weight: 500;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
        }
    }
}


/*------ Pricing Styles ---------*/

.panel.price {
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    >.panel-heading {
        -moz-transition: all .3s ease;
        transition: all .3s ease;
        -o-transition: all .3s ease;
        -webkit-transition: all .3s ease;
    }
    h3 {
        margin-block-end: 0;
        padding: 20px 0;
    }
}

.panel-heading {
    padding: 10px 15px;
    border-block-end: 1px solid $white-2;
    background: $white;
}

.panel.price {
    >.panel-heading {
        color: $white;
    }
    .list-group-item {
        &:last-child {
            border-end-end-radius: 0px;
            border-end-start-radius: 0px;
        }
        &:first-child {
            border-start-end-radius: 0px;
            border-start-start-radius: 0px;
        }
    }
    margin-block-end: 1.5rem;
    box-shadow: 0 4px 25px 0 rgba(168, 180, 208, .1);
    border-radius: 7px;
    background: $white;
}

.price {
    .panel-footer {
        border-block-end: 0px;
        background-color: $white;
        border-inline-start: 0;
        border-inline-end: 0;
    }
    &.panel-color>.panel-body {
        background-color: $white;
    }
}

.product-price {
    font-size: 1rem;
    strong {
        font-size: 1.5rem;
    }
}

.pricing-divider-img {
    position: absolute;
    inset-inline-start: 0;
    width: 100%;
}

.price-detail-wrap .rating-stars {
    text-align: start !important;
}

.ribbon-price {
    position: absolute;
    inset-block-start: 0;
    z-index: 8;
    inset-inline-end: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
}

.ribbon-price .badge {
    float: $float-right;
    transform: rotate( 45deg);
    inset-inline-end: -70px;
    inset-block-start: 21px;
    position: relative;
    text-align: center;
    width: 212px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
}
.pricing.bg-primary-gradient {
    border-radius: 0px 60px 0 60px;
}

.pricing.bg-warning-gradient {
    border-radius: 0px 60px 0 60px;
}

.pricing.bg-success-gradient {
    border-radius: 0px 60px 0 60px;
}

.pricing.bg-danger-gradient {
    border-radius: 0px 60px 0 60px;
}

.price .panel-body .lead strong {
    line-height: 2;
    font-size: 30px;
}

.ribbone1-price .ribbon {
    position: absolute;
    inset-inline-end: 6px;
    inset-block-start: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: end;
}

.ribbone1-price .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: $primary-1;
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    inset-block-start: 19px;
    inset-inline-end: -21px;
}

.ribbone1-price .ribbon span::before {
    content: "";
    position: absolute;
    inset-inline-start: 0px;
    inset-block-start: 100%;
    z-index: -1;
    border-inline-start: 3px solid $primary-1;
    border-inline-end: 3px solid transparent;
    border-block-end: 3px solid transparent;
    border-block-start: 3px solid $primary-1;
}

.ribbone1-price .ribbon span::after {
    content: "";
    position: absolute;
    inset-inline-end: 0px;
    inset-block-start: 100%;
    z-index: -1;
    border-inline-start: 3px solid transparent;
    border-inline-end: 3px solid $primary-1;
    border-block-end: 3px solid transparent;
    border-block-start: 3px solid $primary-1;
}

.price .list-group-flush .list-group-item {
    border: 0 !important;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-block-end: 0px;
    padding-inline-start: 16px;
}

.primary .pricing-divider {
    padding: 1em 0 1em;
    position: relative;
}

.secondary .pricing-divider {
    background: $white;
    padding: 1em 0 1em;
    position: relative;
}

.danger .pricing-divider {
    background: $white !important;
    padding: 1em 0 1em;
    position: relative;
}

.success .pricing-divider {
    background: $white;
    padding: 1em 0 1em;
    position: relative;
}