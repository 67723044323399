/*---------- Header-styles ----------*/

/*Header-Color*/

.color-header {
    .main-header-center .form-control {
        color: $white;

        &::placeholder {
            color: $white !important;
            opacity: 0.3;
        }
    }

    .hor-header .header-brand1 {
        margin-block-start: 4px;
    }
}

.color-header {
    .main-header-center .form-control::placeholder {
        color: $white !important;
        opacity: 0.3;
    }

    .app-header,
    .hor-header {
        background: $primary-1 !important;
        border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    }

    .app-sidebar__toggle:hover {
        color: #fff;
    }

    .demo-icon.nav-link i {
        color: #e7e7f7 !important;
    }

    .animated-arrow {
        background: none;

        span {
            background: #e7e7f7;

            &:before,
            &:after {
                background: #e7e7f7;
            }
        }
    }

    &.active .animated-arrow span {
        background-color: transparent;
    }

    .header {
        .form-inline .form-control {
            border: 1px solid $white-05 !important;
            background: rgba(0, 0, 0, 0.08);
            color: $white;
        }
    }

    .header .form-inline {
        .btn i {
            color: $white !important;
            opacity: 0.5;
        }

        .form-control::placeholder {
            color: $white !important;
            opacity: 0.3;
        }
    }

    .app-sidebar__toggle {
        color: #fff;

        a {
            color: $white;
        }
    }

    .hor-header {
        .header-brand-img.light-logo {
            display: block;
        }
    }

    .logo-horizontal .header-brand-img.light-logo1 {
        display: none;
    }
}

@media (min-width: 992px) {
    .color-header {
        .hor-header.header .header-brand .header-brand-img {
            &.desktop-lgo {
                display: none;
            }

            &.dark-logo {
                display: block;
                // margin-block-start: 5px;
            }
        }
    }
}

@media (min-width: 991px) {
    .color-header {

        .header .nav-link.icon i,
        .header-right .nav-link.icon:hover i {
            color: $white !important;
        }

        .nav-link.icon {
            color: $white !important;
        }

        .nav-link i {
            color: #e7e7f7 !important;

            &:hover {
                color: $white;
            }
        }
    }
}

@media (max-width: 991px) {
    .color-header {
        .hor-header.header .header-brand-img {
            &.mobile-logo {
                display: none !important;
            }

            &.darkmobile-logo {
                display: block;
            }
        }

        .hor-header {
            .header-brand-img {
                &.desktop-logo {
                    display: none;
                }

                &.light-logo {
                    margin: 0 auto;
                    margin-block-start: 6px;
                }
            }
        }
    }
}

@media (max-width: 1024px) and (min-width: 992px) {
    .color-header {
        .hor-header.header .header-brand-img {

            &.desktop-lgo,
            &.mobile-logo {
                display: none !important;
            }

            &.dark-logo {
                display: block !important;
                // margin-block-start: 5px;
            }
        }
    }
}

@media (max-width: 991px) {
    .color-header {
        .app-header .header-brand1 .header-brand-img.light-logo1 {
            display: none !important;
        }

        .app-header .header-brand1 .header-brand-img.desktop-logo {
            display: block !important;
        }

        .app-header.header .header-brand-img.desktop-logo {
            display: block;
        }

        .logo-horizontal .header-brand-img.light-logo1 {
            display: none !important;
        }
    }
}

.dark-mode.color-header {

    .app-header,
    .hor-header {
        background: $primary-1;
    }

    .header .form-inline .form-control {
        border: 1px solid $white-05 !important;
        color: $white;
    }
}

/*Header-dark*/

.dark-header {

    .app-header,
    .hor-header {
        background: $dark-theme-1 !important;
        border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    }

    .hor-header .header-brand1 {
        margin-block-start: 4px;
    }

    .animated-arrow {
        background: none;

        span {
            background: $primary-1;

            &:before,
            &:after {
                background: $primary-1;
            }
        }
    }

    &.active .animated-arrow span {
        background-color: transparent;
    }

    .header.hor-header .nav-link.icon,
    .header-right-icons .nav-link.icon:hover {
        color: #e7e7f7 !important;
    }

    .app-header.header .nav-link:hover {
        color: $white;
    }

    .app-sidebar__toggle:hover {
        color: #fff;
    }

    .header .main-header-center .form-control {
        border: 1px solid $white-1 !important;
        color: $white;
        background: #353554;
    }

    .header .form-inline {
        .btn i {
            color: $white;
            opacity: 0.5;
        }

        .form-control::placeholder {
            color: $white;
            opacity: 0.3;
        }
    }

    .header.hor-header .header-brand-img {
        &.desktop-logo {
            display: block;
        }

        &.light-logo {
            display: block;
        }
    }

    &.horizontal .logo-horizontal .header-brand-img.light-logo1 {
        display: none;
    }

    .app-header .header-right-icons .nav-link.icon {
        color: #d5d7df !important;
    }

    .app-header .header-right-icons .nav-link.icon:hover {
        color: $white;
    }

    .app-sidebar__toggle {
        color: #d5d7df !important;
    }

    .app-sidebar__toggle:after {
        background: none;
    }

    .nav-link.icon i::after {
        background: none;
    }

    &.transparent-mode .app-header.sticky-pin {
        background: $dark-theme-1;
    }
}

@media (max-width: 992px) {
    .dark-header {
        .app-header .header-brand1 .header-brand-img {
            &.light-logo1 {
                display: none !important;
                // margin-block-start: 5px;
            }

            &.desktop-logo {
                display: block;
            }
        }

        .hor-header {
            .header-brand-img {
                &.desktop-logo {
                    display: none;
                }

                &.light-logo {
                    margin: 0 auto;
                    margin-block-start: 6px;
                }
            }
        }

        .app-header.header .header-brand-img.desktop-logo {
            display: block;
        }

        .logo-horizontal .header-brand-img.light-logo1 {
            display: none !important;
        }
    }
}

@media (min-width: 992px) {
    .dark-header {
        .hor-header.header .header-brand .header-brand-img {
            &.desktop-lgo {
                display: none;
            }

            &.dark-logo {
                display: block;
                // margin-block-start: 5px;
            }
        }
    }
}

@media (max-width: 1024px) and (min-width: 992px) {
    .dark-header {
        .hor-header.header .header-brand-img {

            &.desktop-lgo,
            &.mobile-logo {
                display: none !important;
            }

            &.dark-logo {
                display: block !important;
                // margin-block-start: 5px;
            }
        }
    }
}

.light-mode.dark-header .hor-header .mobile-header.hor-mobile-header {
    background: #30304d !important;
}

/*Header-light*/

.dark-mode.header-light {

    .app-header,
    .hor-header {
        background: $white;
        border-block-end: 1px solid rgb(236, 240, 250);
    }

    .app-sidebar {
        box-shadow: none;
    }

    .animated-arrow {
        span {
            background: #5c678f;

            &:before,
            &:after {
                background: #5c678f;
            }
        }
    }

    &.active .animated-arrow span {
        background-color: transparent;
    }

    .header .form-inline .form-control {
        border: 1px solid transparent !important;
        background: #f1f5ff;
        color: $black;
    }

    .hor-header .header-brand-img.desktop-logo-1 {
        display: none;
    }

    .drop-profile a {
        color: #171a29;
    }

    .hor-header .header-brand-img.desktop-logo {
        display: block;
    }

    .header .nav-link.icon i {
        color: $dark-theme-1;
    }

    .header .nav-link.icon i {
        color: $primary-1;
    }

    .app-sidebar__toggle {
        a {
            color: #555b95;
        }
    }
}

@media (max-width: 575.98px) {
    .dark-mode.header-light .header .form-inline .form-control {
        background: #191d43;
    }
}

@media (max-width: 991px) {
    .dark-mode.header-light {
        .hor-header .header-brand-img.desktop-logo {
            display: none;
        }

        .app-header .header-brand1 .header-brand-img {
            &.desktop-logo {
                display: none;
            }

            &.light-logo1 {
                display: block !important;
            }
        }

        .mobile-header {
            background-color: #ffffff;
        }

        .mobile-header .header-brand-img.desktop-logo {
            display: none !important;
        }

        .mobile-header .header-brand-img.desktop-logo.mobile-light {
            display: block !important;
        }
    }
}

@media (max-width: 991px) and (min-width: 568px) {
    .app.dark-mode.header-light {
        .app-header .header-brand .header-brand-img {
            &.desktop-lgo {
                display: block;
                // margin-block-start: 5px;
            }

            &.dark-logo,
            &.mobile-logo,
            &.darkmobile-logo {
                display: none !important;
            }
        }
    }
}

@media (max-width: 1024px) and (min-width: 992px) {
    .dark-mode.header-light {
        .hor-header.header .header-brand-img {

            &.dark-logo,
            &.mobile-logo {
                display: none !important;
            }

            &.desktop-lgo {
                display: block !important;
                // margin-block-start: 5px;
            }
        }
    }
}

/*Header-Gradient-header*/

.gradient-header {
    .hor-header .header-brand1 {
        margin-block-start: 4px;
    }

    .app-header,
    .hor-header {
        background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%) !important;
        border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    }

    .main-header-center .form-control {
        color: $white;

        &::placeholder {
            color: $white !important;
            opacity: 0.3;
        }
    }

    .animated-arrow {
        background: none;

        span {
            background: #e7e7f7;

            &:before,
            &:after {
                background: #e7e7f7;
            }
        }
    }

    &.active .animated-arrow span {
        background-color: transparent;
    }

    .app-header.header .nav-link:hover {
        color: $white;
    }

    .app-sidebar__toggle:hover {
        color: #fff;
    }

    .demo-icon.nav-link.icon i {
        color: #e7e7f7 !important;
    }

    .header {
        .main-header-center .form-control {
            border: 1px solid $white-1 !important;
            background: rgba(0, 0, 0, 0.08) !important;
            color: $white;
        }

        .main-header-center .form-control:placeholder {
            color: $white !important;
            opacity: 0.5;
        }
    }

    .app-sidebar__toggle {
        color: #fff;

        a {
            color: $white;
        }
    }

    .app-header .header-right-icons .nav-link.icon {
        color: $white;
    }

    .app-header .header-right-icons .nav-link.icon:hover {
        color: $white !important;
    }

    .hor-header .header-right-icons .nav-link.icon {
        color: $white !important;
    }
}

@media (min-width: 991px) {
    .gradient-header {

        .header .nav-link.icon i,
        .header-right .nav-link.icon:hover i {
            color: $white !important;
        }

        .nav-link.icon i {
            color: $white !important;
        }

        .nav-link i {
            color: #e7e7f7 !important;

            &:hover {
                color: $white;
            }
        }

        &.horizontal .logo-horizontal .header-brand-img.light-logo1 {
            display: none;
        }

        &.horizontal .logo-horizontal .header-brand-img.desktop-logo {
            display: block;
        }
    }
}

@media (min-width: 992px) {
    .gradient-header {
        .hor-header.header .header-brand1 .header-brand-img {
            &.desktop-logo {
                display: none !important;
            }

            &.light-logo {
                display: block;
            }
        }

        .hor-header.header .header-brand2 .header-brand-img {
            &.desktop-logo {
                display: none !important;
            }

            &.light-logo {
                display: block;
            }
        }
    }
}

@media (max-width: 991px) and (min-width: 575.98px) {
    .gradient-header {
        .app-header .header-brand .header-brand-img {
            &.dark-logo {
                display: block;
                // margin-block-start: 5px;
            }

            &.desktop-lgo,
            &.mobile-logo,
            &.darkmobile-logo {
                display: none !important;
            }
        }
    }
}

@media (max-width: 991px) {
    .gradient-header {
        .app-header .header-brand1 .header-brand-img {
            &.desktop-logo {
                display: block;
            }

            &.light-logo1 {
                display: none !important;
            }
        }

        .navresponsive-toggler span {
            color: $white !important;
        }

        .hor-header {
            .header-brand-img {
                &.desktop-logo {
                    display: block !important;
                }

                &.light-logo {
                    margin: 0 auto;
                    margin-block-start: 6px;
                    display: block;
                }
            }
        }

        .app-header.header .header-brand-img.desktop-logo {
            display: block;
        }

        .logo-horizontal .header-brand-img.light-logo1 {
            display: none !important;
        }
    }
}

@media (max-width: 1024px) and (min-width: 992px) {
    .gradient-header {
        .hor-header.header .header-brand-img {

            &.desktop-lgo,
            &.mobile-logo {
                display: none !important;
            }

            &.dark-logo {
                display: block !important;
                // margin-block-start: 5px;
            }
        }
    }
}

.dark-mode.gradient-header {

    .app-header,
    .hor-header {
        background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%) !important;
    }

    .header .form-inline .form-control {
        border: 1px solid $white-05 !important;
        color: $white;
    }
}

/*---------- Leftmenu-styles ----------*/

/*Light-menu*/

.light-menu {
    &.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .side-menu-label1 a {
        color: $default-color !important;
    }

    .app-sidebar {
        background: $white;
        border-color: $border !important;

        .app-sidebar__user {
            border-block-end: 1px solid $border;
        }

        .side-menu .sidemenu_icon {
            color: $white !important;
        }

        .app-sidebar__user {
            .user-pic .avatar-xxl {
                border: 3px solid #ebeff8;
            }

            .user-pic::before {
                border: 2px solid #fff;
            }

            .user-info h5 {
                color: #263871;
            }
        }

    }

    .slide a.active .sidemenu_icon,
    .side-menu__item:hover .sidemenu_icon {
        color: $white;
    }

    .slide-menu li {

        &.active>a,
        &:hover>a {
            color: $primary-1 !important;
        }
    }

    .side-item.side-item-category {
        opacity: 0.7 !important;
    }

    &.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
        display: block !important;
    }

    &.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
        display: none !important;
    }

    &.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
        display: block !important;
    }

    &.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
        display: none !important;
    }

    &.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .side-menu-label1 {
        border-block-end-color: $border !important;
    }

    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu2 {
        border-color: $border;
    }
}

.dark-mode.light-menu {
    .app-sidebar {
        background: $white !important;

        .app-sidebar__user {
            border-block-end: 1px solid $border;
        }

        .app-sidebar__user {
            .user-pic .avatar-xxl {
                border: 3px solid #ebeff8;
            }

            .user-pic::before {
                border: 2px solid #fff;
            }
        }
    }

    .slide-menu li {

        &.active>a,
        &:hover>a {
            color: $primary-1 !important;
        }
    }
}

@media (min-width: 992px) {
    .light-menu {
        .app-sidebar__logo {
            border-block-end: 1px solid $border;
            border-inline-end: 1px solid $border;
        }

        .app-sidebar .app-sidebar__logo .header-brand-img {
            &.desktop-lgo {
                display: block;
                margin: 0 auto;
                // margin-block-start: 5px;
            }

            &.dark-logo {
                display: none;
            }
        }

        &.sidenav-toggled {
            .app-sidebar .app-sidebar__logo .header-brand-img {
                &.desktop-lgo {
                    display: none;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled1 {
            .app-sidebar .app-sidebar__logo .header-brand-img {
                &.desktop-lgo {
                    display: block;
                    margin: 0 auto;
                    // margin-block-start: 5px;
                }

                &.dark-logo {
                    display: none;
                }
            }
        }

        &.light-menu.dark-mode.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
            background: #f1f1f1 !important;
        }

        &.light-menu.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
            background: #f1f1f1 !important;
        }
    }

    .dark-mode.light-menu {
        .side-header {
            border-block-end: 1px solid $border;
            border-inline-end: 1px solid $border;
            inset-block-start: 0px;
        }

        .side-menu h3 {
            color: #000;
        }

        .side-menu__item.active:hover,
        .side-menu__item.active:focus {
            color: #ffffff;
        }

        .app-sidebar .side-header .header-brand-img {
            &.light-logo1 {
                display: block !important;
                margin: 0 auto;
                // margin-block-start: 5px;
            }

            &.desktop-logo {
                display: none !important;
            }
        }

        &.sidenav-toggled {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: block !important;
                    margin: 0 auto;
                    // margin-block-start: 5px;
                }

                &desktop-logo {
                    display: none !important;
                }
            }
        }
    }
}

.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: block !important;
}

.app.sidebar-mini.dark-mode.hover-submenu1.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

/*Color-menu*/

.color-menu {
    .app-sidebar {
        border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
    }

    .app-sidebar .side-header {
        background: $primary-1;
        border-inline-end: 1px solid rgba(255, 255, 255, 0.1);

        .side-item.side-item-category {
            color: $white;
            opacity: 0.3;
        }

        .side-menu .side-menu__icon,
        .side-menu .side-menu__item {
            color: #e7eefb;
        }

        .slide a.active .sidemenu_icon,
        .side-menu__item:hover .sidemenu_icon {
            color: $white !important;
            opacity: inherit;
        }

        .side-menu .slide a.active,
        .side-menu .slide a:hover {
            color: $white !important;
            opacity: inherit;
        }

        .slide .side-menu__item.active,
        .slide .side-menu__item:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        .side-menu__item.active:hover,
        .side-menu__item.active:focus {
            background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%) !important;
        }

        .side-menu__item:hover .side-menu__label,
        .side-menu__item:hover .side-menu__icon,
        .side-menu__item:hover .angle {
            color: $white !important;
            opacity: inherit;
        }

        .app-sidebar__user {
            border-block-end: 1px solid $white-1;

            .user-info h5 {
                color: $white;
            }

            .app-sidebar__user-name.text-muted {
                color: $white !important;
                opacity: 0.7;
            }
        }

        .app-sidebar__user .user-pic .avatar-xxl {
            border: 3px solid $white;
        }
    }

    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu2 {
        background-color: $primary-1;
        border-color: $border-dark;
    }

    &.horizontal .side-menu .slide .side-menu__item.active {
        color: $white !important;
    }

    .slide-item.active,
    .slide-item:hover,
    .slide-item:focus {
        color: $white !important;
    }

    .is-expanded .sub-slide-item.active,
    .sub-side-menu__item.active.is-expanded {
        color: $white;
    }
}

@media (min-width: 992px) {
    .color-menu {
        .side-header {
            border-block-end: 1px solid $white-1;
            border-inline-end: 1px solid $white-1;
        }

        .app-sidebar .side-header .header-brand-img {
            &.desktop-logo {
                display: block;
                margin: 0 auto;
            }

            &.light-logo1 {
                display: none;
            }
        }

        &.sidenav-toggled {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none;
                }

                &.toggle-logo {
                    display: block !important;
                }

                &.light-logo,
                &.light-logo1,
                &.mobile-light {
                    display: none;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: block !important;
                    margin: 0 auto;
                }

                &.toggle-logo {
                    display: none !important;
                }

                &.light-logo,
                &.light-logo1 {
                    display: none !important;
                }
            }
        }

        &.hover-submenu.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.toggle-logo {
                    display: block !important;
                }
            }
        }

        &.hover-submenu.dark-mode.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.toggle-logo {
                    display: block !important;
                }
            }
        }

        &.hover-submenu1.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.toggle-logo {
                    display: block !important;
                }
            }
        }

        &.hover-submenu1.dark-mode.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.toggle-logo {
                    display: block !important;
                }
            }
        }

        &.sidenav-toggled {
            .slide-menu {
                background: $primary-1 !important;
                color: $white;
            }

            .sub-side-menu__item,
            & .sub-side-menu__item2 {
                color: #e7eefb;
            }

            .sub-slide-item,
            & .sub-slide-item2 {
                color: #e7eefb;
            }

            .slide-item {

                &:hover,
                &:focus {
                    color: $white !important;
                }
            }
        }

        .sub-side-menu__item {
            color: $white;
        }
    }
}

.dark-mode.color-menu {
    .app-sidebar {
        background: $primary-1 !important;

        .side-menu .slide a {
            color: $white !important;
            opacity: 0.7;
        }

        .side-menu__item {

            &:hover .side-menu__icon,
            &:hover .side-menu__label,
            &:focus .side-menu__icon,
            &:focus .side-menu__label {
                color: $white !important;
                opacity: 0.7;
            }
        }
    }

    .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
        border: 3px solid $white;
    }

    .app-sidebar .app-sidebar__user .user-pic::before {
        border: 2px solid $white;
    }

    .side-item.side-item-category {
        color: $white;
        opacity: 0.5;
    }

    .app-sidebar ul li a {
        color: $white;
        opacity: 0.8;
    }

    .slide-menu li {

        &.active>a,
        &:hover>a {
            color: $white !important;
            opacity: inherit;
        }
    }

    .app-sidebar {

        .slide-menu .slide-item:hover,
        .side-menu__item.active .side-menu__icon {
            color: $white !important;
            opacity: inherit;
        }
    }

    .slide.is-expanded .side-menu__item {
        color: $white !important;
        opacity: inherit;
    }
}

@media (min-width: 992px) {
    .dark-mode.color-menu .app-sidebar__logo {
        border-block-end: 1px solid $white-1;
        border-inline-end: 1px solid $white-1;
    }
}

/*dark-menu*/

.dark-menu {
    .app-sidebar {
        background: $dark-theme-1 !important;
        border-inline-end: 1px solid rgba(255, 255, 255, 0.1);

        .side-item.side-item-category {
            color: $white;
            opacity: 0.3;
        }

        .side-header {
            background: $dark-theme-1 !important;
            border-block-end: 1px solid rgba(255, 255, 255, 0.1);
        }

        .side-menu h3 {
            color: rgba(255, 255, 255, 0.3);
        }

        .side-menu .side-menu__icon {
            color: $primary-1 !important;
            opacity: 0.7;
        }

        .side-menu a {
            color: $text-color !important;
        }

        .side-menu__item {
            color: $primary-1 !important;
        }

        .slide-item {
            color: $text-color !important;
        }

        .slide a.active .side-menu__icon,
        .side-menu__item:hover .side-menu__icon {
            color: $primary-1 !important;
            opacity: inherit;
        }

        .side-menu .slide a.active,
        .side-menu .slide a:hover {
            color: $primary-1 !important;
            opacity: inherit;
        }

        .side-menu__item:hover .side-menu__label,
        .side-menu__item:hover .side-menu__icon,
        .side-menu__item:hover .angle {
            color: $primary-1 !important;
            opacity: inherit;
        }

        .side-menu__item:hover .side-menu__icon,
        .side-menu__item:hover .side-menu__label,
        .side-menu__item:focus .side-menu__icon,
        .side-menu__item:focus .side-menu__label {
            color: $primary-1 !important;
        }

        .app-sidebar__user {
            border-block-end: 1px solid $white-1;

            .user-info h5 {
                color: $white;
            }

            .app-sidebar__user-name.text-muted {
                color: $white !important;
                opacity: 0.5;
            }
        }
    }

    &.sidebar-mini.sidenav-toggled .slide-menu {
        background: $dark-theme-1 !important;
    }

    .slide-menu li .slide-item:before {
        color: $white;
    }

    .side-menu-label1 {
        border-block-end-color: $border-dark !important;
    }

    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu2 {
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
}

@media (min-width: 992px) {
    .dark-menu {
        .side-header {
            border-block-end: 1px solid $white-1;
            border-inline-end: 1px solid $white-1;
        }

        .app-sidebar .side-header .header-brand-img {
            &.desktop-logo {
                display: block;
                margin: 0 auto;
            }

            &.light-logo1 {
                display: none;
            }
        }

        &.sidenav-toggled {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none;
                }

                &.light-logo1 {
                    display: block;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: block;
                    margin: 0 auto;
                }

                &.light-logo1 {
                    display: none !important;
                }
            }
        }

        &.hover-submenu.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none;
                }
            }
        }

        &.hover-submenu1.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none;
                }
            }
        }

        &.dark-mode.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: block !important;
                }

                &.toggle-logo {
                    display: none;
                }
            }
        }

        &.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: none !important;
                }

                &.toggle-logo {
                    display: block !important;
                }
            }
        }

        &.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: none !important;
                }

                &.toggle-logo {
                    display: block !important;
                }
            }
        }
    }
}

/*Graident-Color*/

.gradient-menu {
    &.horizontal {
        .app-sidebar {
            background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%) !important;
        }
    }

    .app-sidebar {
        background: linear-gradient(to bottom, $primary-1 0%, #8e77fa 100%) !important;

        .side-item.side-item-category {
            color: $white;
            opacity: 0.3;
        }

        .side-header {
            background: $primary-1;
            border-block-end: 1px solid $white-1;
            border-inline-end: 1px solid $white-1;
        }

        .side-menu__item.active::after {
            background: #fff;
        }

        .side-menu__item:hover::after {
            background: #fff;
        }

        .side-menu .slide a {
            color: #e7eefb;
        }

        .side-menu h3 {
            color: rgba(255, 255, 255, 0.3);
        }

        .side-menu .side-menu__icon {
            color: #e7eefb !important;
            opacity: 0.7;
        }

        .slide-menu li .slide-item:before {
            color: #c7c9eb;
        }

        .side-menu__item:hover .side-menu__icon,
        .side-menu__item:hover .side-menu__label,
        .side-menu__item:focus .side-menu__icon,
        .side-menu__item:focus .side-menu__label {
            color: #ffffff !important;
        }

        .side-menu .slide a {
            color: #e7eefb;
        }

        .side-menu__item {
            color: #e7eefb;
        }

        .slide-item {
            color: #e7eefb;
        }

        .slide a.active .side-menu__icon,
        .side-menu__item:hover .side-menu__icon {
            color: $white !important;
            opacity: inherit;
        }

        .side-menu .slide a.active,
        .side-menu .slide a:hover {
            color: $white !important;
            opacity: inherit;
        }

        .side-menu__item:hover .side-menu__label,
        .side-menu__item:hover .side-menu__icon,
        .side-menu__item:hover .angle {
            color: $white !important;
            opacity: inherit;
        }

        .app-sidebar__user {
            border-block-end: 1px solid $white-1;

            .user-info h5 {
                color: $white;
            }

            .app-sidebar__user-name.text-muted {
                color: $white !important;
            }
        }

        .app-sidebar__user .user-pic .avatar-xxl {
            border: 3px solid $white;
        }
    }

    &.hover-submenu1 .side-menu-label1 {
        border-block-end-color: $border-dark !important;
    }

    &.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
        background: linear-gradient(to bottom, $primary-1 0%, #8e77fa 100%) !important;
    }

    &.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
        background: linear-gradient(to bottom, $primary-1 0%, #8e77fa 100%) !important;
    }
}

@media (min-width: 992px) {
    .gradient-menu {
        .app-sidebar {
            border-block-end: 1px solid $white-1;
            border-inline-end: 1px solid $white-1;
        }

        .app-sidebar .side-header .header-brand-img {
            &.desktop-logo {
                display: block;
                margin: 0 auto;
            }

            &.light-logo1 {
                display: none;
            }
        }

        &.sidenav-toggled {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none;
                }

                &.toggle-logo {
                    display: block !important;
                    // margin-block-start: 5px;
                }

                &.light-logo,
                &.light-logo1,
                &.mobile-light {
                    display: none;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled-open {
            .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: block;
                    margin: 0 auto;
                    // margin-block-start: 5px;
                }

                &.toggle-logo {
                    display: none !important;
                }

                &.light-logo1 {
                    display: none !important;
                }
            }
        }

        &.horizontal .horizontal-main .slide .slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu2 {
            background: linear-gradient(to bottom, $primary-1 0%, #8e77fa 100%);
            border-color: $border-dark;
        }
    }
}

.dark-mode.gradient-menu {
    .app-sidebar {
        background: linear-gradient(to bottom, $primary-1 0%, #8e77fa 100%) !important;

        .side-menu .slide a {
            color: $white;
            opacity: 0.7;
        }
    }

    .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
        border: 3px solid $white;
    }

    .app-sidebar .app-sidebar__user .user-pic::before {
        border: 2px solid $white;
    }

    .side-item.side-item-category {
        color: $white;
        opacity: 0.5;
    }

    .app-sidebar ul li a {
        color: $white;
        opacity: 0.8;
    }

    .slide-menu li {

        &.active>a,
        &:hover>a {
            color: $white !important;
            opacity: inherit;
        }
    }

    .app-sidebar {

        .slide-menu .slide-item:hover,
        .side-menu__item.active .side-menu__icon {
            color: $white !important;
            opacity: inherit;
        }
    }

    .slide.is-expanded .side-menu__item {
        color: $white !important;
        opacity: inherit;
    }
}

@media (min-width: 992px) {
    .dark-mode.gradient-menu {
        .app-sidebar__logo {
            border-block-end: 1px solid $white-1;
            border-inline-end: 1px solid $white-1;
        }

        &.horizontal .app-sidebar {
            background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%) !important;
        }
    }
}

/*---------- Horizontalmenu-styles ----------*/

.dark-mode.gradient-header .mobile-header {
    border-block-end: 1px solid rgb(100, 76, 252) !important;
}

.dark-mode {
    &.color-header.gradient-menu .app-sidebar {
        box-shadow: none;
    }

    &.gradient-header.gradient-header .app-sidebar {
        box-shadow: none;
    }

    &.color-menu.color-header .app-sidebar {
        box-shadow: none;
    }

    &.light-menu.color-header .app-sidebar {
        box-shadow: none;
    }
}

/*dark-hormenu*/

.gradient-header.color-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.gradient-header.color-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.dark-header.color-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.color-header.color-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.gradient-header.gradient-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.dark-header.gradient-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.color-header.gradient-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.gradient-header.dark-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

.dark-header.dark-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
}


.color-header.dark-menu .app-sidebar {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: none;
}

@media (max-width: 991px) {
    .dark-mode .mobile-header {
        background-color: #30304d;
        border: 1px solid transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.color-header .header-brand-img.desktop-logo {
    display: none;
}

.color-header.horizontal .header-brand-img.desktop-logo {
    display: block;
}

.color-header .mobile-header.hor-mobile-header {
    background: $primary-1;
}

@media (max-width: 991px) {
    .color-header .mobile-header .header-brand-img.desktop-logo {
        display: block;
    }

    .color-header .mobile-header .header-brand-img.mobile-light {
        display: none !important;
    }

    .dark-header .app-header .header-right-icons .responsive-navbar .nav-link.icon {
        color: $default-color !important;
    }

    .dark-header .navresponsive-toggler span {
        color: $white;
    }
}

.color-header .main-header-center .form-control {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.color-header .app-sidebar .side-header .header-brand-img.light-logo {
    display: none;
}

.color-header.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
    display: block;
}

.color-header.gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
    display: none;
}

.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.desktop-logo {
        display: block !important;
    }

    &.light-logo {
        display: none;
    }

    &.light-logo1 {
        display: block;
    }
}

.gradient-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.desktop-logo {
        display: none !important;
    }

    &.light-logo {
        display: none !important;
    }

    &.toggle-logo {
        display: block !important;
    }
}

.gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
    &.desktop-logo {
        display: none !important;
    }
}

.gradient-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.desktop-logo {
        display: none !important;
    }

    &.light-logo {
        display: none !important;
    }

    &.toggle-logo {
        display: block !important;
    }
}

.header-light .hor-header .header-brand-img.light-logo {
    display: none;
}

.header-light .main-header-center .form-control {
    color: $default-color !important;
    background-color: $white;
    border: 1px solid $border !important;
}

.header-light .header .dropdown .nav-link.icon:hover {
    background: none !important;
}

.header-light.light-menu .side-header {
    border-block-end: 1px solid $border;
    background: #fff;
}

.header-light.light-menu .app-sidebar {
    background: #fff;
}

.header-light.light-menu .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }

    &.desktop-logo {
        display: none !important;
    }
}

.header-light.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: none !important;
    }
}

.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }
}

.color-header.light-menu .side-header {
    border-block-end: 1px solid $border;
    background: #fff;
}

.color-header.light-menu .app-sidebar {
    background: #fff;
}

.color-header.light-menu .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }

    &.desktop-logo {
        display: none !important;
    }
}

.color-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: none !important;
    }
}

.color-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }
}

.color-header .navresponsive-toggler span {
    color: $white !important;
}

.gradient-header.light-menu .side-header {
    border-block-end: 1px solid $border;
    background: #fff;
}

.gradient-header.light-menu .app-sidebar {
    background: #fff;
}

.gradient-header.light-menu .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }

    &.desktop-logo {
        display: none !important;
    }
}

.gradient-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: none !important;
    }
}

.gradient-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }
}

.dark-mode .apexcharts-radialbar-track.apexcharts-track path {
    stroke: #21203a;
}

.dark-mode .apex-charts text {
    fill: #fff;
}

.light-mode.color-header.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
    display: none;
}

.light-mode.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.dark-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.gradient-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu .side-header {
    background: #ffffff;
}

.dark-header .mobile-header.hor-mobile-header {
    background: #30304d;
}

.gradient-header .mobile-header.hor-mobile-header {
    background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%) !important;
}

@media (max-width: 991px) {
    .dark-header .mobile-header {
        .header-brand-img {
            &.desktop-logo {
                display: block;
            }

            &.mobile-light {
                display: none !important;
            }
        }
    }
}

@media (max-width: 991px) {
    .gradient-header .mobile-header {
        .header-brand-img {
            &.desktop-logo {
                display: block;
            }

            &.mobile-light {
                display: none !important;
            }
        }
    }
}

.dark-mode.header-light .main-header-center .form-control {
    border: 1px solid #ecf0fa !important;
}

.dark-mode.light-menu .side-menu__item {
    color: $default-color;
}

.dark-mode.horizontal.light-menu .side-menu>li>a {
    color: $default-color;
}

.dark-mode.horizontal.light-menu .side-menu .sub-side-menu__label,
.dark-mode.horizontal.light-menu .sub-slide-label,
.dark-mode.horizontal.light-menu .sub-side-menu__label2 {
    color: $default-color !important;
}

.dark-mode.horizontal.light-menu .horizontal-main .slide .slide-menu,
.dark-mode.horizontal.light-menu .horizontal-main .slide .sub-slide-menu,
.dark-mode.horizontal.light-menu .horizontal-main .slide .sub-slide-menu2 {
    background-color: $white;
}

.dark-mode.light-menu .side-menu__icon {
    color: $primary-1 !important;
}

.dark-mode.light-menu.sidenav-toggled .side-menu__icon {
    color: $default-color !important;
}

.dark-mode.light-menu.sidenav-toggled-open .side-menu__icon {
    color: $primary-1 !important;
}

.dark-mode.light-menu .slide.is-expanded a {
    color: $default-color;
}

.dark-mode.light-menu.hover-submenu .slide-item {
    color: $default-color !important;
}

.dark-mode.light-menu.hover-submenu1 .slide-item {
    color: $default-color !important;
}

.dark-mode.light-menu .side-menu .sub-category,
.dark-mode.light-menu .sub-side-menu__item,
.dark-mode.light-menu .sub-slide-item,
.dark-mode.light-menu .sub-side-menu__item2,
.dark-mode.light-menu .sub-slide-item2 {
    color: $default-color;
}

.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled {
    .slide-menu {
        background: $dark-theme-1 !important;
    }

    .side-menu-label1 {
        border-block-end-color: $border-dark !important;

        a {
            color: $text-color !important;
        }
    }
}

.dark-mode.hover-submenu.sidebar-mini.sidenav-toggled {
    .slide-menu {
        background: $dark-theme-1 !important;
    }
}

.dark-mode.header-light {
    &.sidebar-mini .app-header .light-logo1 {
        display: block !important;
    }

    &.sidebar-mini .app-header.header .header-brand-img.desktop-logo {
        display: none;
    }
}

/*--Boxed--*/

@media (min-width: 1400px) {
    .horizontal.layout-boxed {
        .stickyClass {
            .app-sidebar {
                max-width: 1400px;
            }
        }
    }

    .layout-boxed.horizontal .hor-header .container,
    .layout-boxed.horizontal .horizontal-main .container,
    .layout-boxed.horizontal .main-content.hor-content .container {
        max-width: 95% !important;
    }

    body.layout-boxed {
        background: #cfd1e2;
    }

    body.transparent-mode.layout-boxed {
        background: var(--transparent-body);

        .page {
            background: var(--transparent-body);
            box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
        }

        &.bg-img1 .page {
            background: url(../images/media/bg-img1.jpg);
            background-blend-mode: overlay;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            content: "";
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-start: 0;
        }

        &.bg-img2 .page {
            background: url(../images/media/bg-img2.jpg);
            background-blend-mode: overlay;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            content: "";
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-start: 0;
        }

        &.bg-img3 .page {
            background: url(../images/media/bg-img3.jpg);
            background-blend-mode: overlay;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            content: "";
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-start: 0;
        }

        &.bg-img4 .page {
            background: url(../images/media/bg-img4.jpg);
            background-blend-mode: overlay;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            content: "";
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-start: 0;
        }
    }

    body.layout-boxed.dark-mode {
        background: #414165;
    }

    .layout-boxed {
        .page {
            width: 1400px;
            margin: 0 auto;
            background: #f2f3f9;
            position: relative;
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: column;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -ms-flex-pack: center;
            -webkit-box-pack: center;
            -webkit-box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
            box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
        }

        .app-header.header {
            width: 1400px !important;
            margin: 0 auto;
        }

        .app-sidebar {
            inset-inline-start: auto !important;
        }

        .side-header {
            inset-inline-start: auto !important;
            inset-inline-end: auto !important;
        }

        h3.number-font {
            font-size: 1.8rem;
        }

        .sticky-pin .horizontal-main.hor-menu {
            width: 1400px !important;
        }

        .hor-content .container,
        .hor-header .container,
        .horizontal-main.hor-menu .container {
            max-width: 95%;
        }

        .main-sidemenu .slide-right {
            inset-inline-end: 6px;
        }

        .main-sidemenu .slide-left {
            inset-inline-start: 8px;
        }

        .browser-stats img {
            padding: 5px;
            width: 30px;
            height: 30px;
        }
    }

    .layout-boxed .media.media-xs.overflow-visible {
        display: inline-block;
    }

    .dark-mode.layout-boxed .page {
        background: #22223d;
        -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
    }
}

/*--- Scrollabel-header ----*/

.scrollable-layout {

    .app-header,
    .mobile-header {
        position: absolute !important;
    }

    .app-sidebar {
        position: absolute;
    }

    .side-header {
        position: absolute;
    }

    .page {
        position: relative;
    }

    .sticky.sticky-pin {
        position: absolute;
    }

    .navbar {
        position: inherit;
    }
}

@media (max-width: 991px) {
    .scrollable-layout {
        .responsive-navbar.navbar .navbar-collapse {
            position: absolute;
        }

        .app-header,
        .hor-header {
            position: absolute;
        }
    }
}

/*--- Only Demo Purpose scss Icon-Text----*/


//Transparent mode

.transparent-mode {

    //Transparent light-menu
    &.light-menu {
        .app-sidebar {
            background-color: $white !important;
        }

        .side-menu__item {
            color: $default-color;
        }

        .side-menu .side-menu__icon {
            color: $primary-1 !important;
        }

        &.sidenav-toggled .side-menu .side-menu__icon {
            color: $default-color !important;
        }

        &.sidenav-toggled-open .side-menu .side-menu__icon {
            color: $primary-1 !important;
        }

        .side-header {
            border-block-end: 1px solid $border;
            background: $white !important;
            border-inline-end-color: $border !important;
        }

        .side-menu .sub-category,
        .sub-side-menu__item,
        .sub-slide-item,
        .sub-side-menu__item2,
        .sub-slide-item2 {
            color: #74829c;
        }

        .slide.is-expanded a {
            color: $default-color;
        }

        .slide-menu li .slide-item:before {
            color: #68798b;
        }

        .slide-menu li .slide-item {
            color: $default-color;
        }

        .app-sidebar.sidemenu-scroll .side-header {
            background: $white !important;
        }

        &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: $white;
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
            .slide-menu {
                background: $white;
            }

            .side-menu-label1 {
                border-block-end: 1px solid $border;

                a {
                    color: $default-color;
                }
            }
        }

        .side-header .header-brand-img {
            &.light-logo1 {
                display: block !important;
            }

            &.desktop-logo {
                display: none !important;
            }
        }

        &.sidenav-toggled .side-header .header-brand-img {
            &.light-logo {
                display: block !important;
            }

            &.toggle-logo {
                display: none !important;
            }
        }

        &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
            &.light-logo1 {
                display: block !important;
            }

            &.desktop-logo {
                display: none !important;
            }

            &.light-logo {
                display: none !important;
            }
        }

        &.app.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
            &.light-logo1 {
                display: none !important;
            }

            &.light-logo {
                display: block !important;
            }
        }

        &.app.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
            &.light-logo1 {
                display: none !important;
            }

            &.light-logo {
                display: block !important;
            }
        }

        &.horizontal {
            .horizontal-main.fixed-header {
                background-color: $white !important;
            }
        }

        @media (max-width: 992.98px) {
            &.horizontal {
                .horizontal-main {
                    background-color: $white !important;
                }
            }

            &.app.sidebar-mini .app-sidebar {
                background-color: $white !important
            }
        }

        &.horizontal .side-menu>li>a {
            color: $default-color;
        }

        &.horizontal .horizontal-main .slide .slide-menu,
        .horizontal .horizontal-main .slide .sub-slide-menu {
            background-color: $white;
        }

        &.horizontal .side-menu .sub-side-menu__label,
        .transparent-mode .sub-slide-label,
        .transparent-mode .sub-side-menu__label2 {
            color: $default-color;
        }

        &.horizontal .horizontal-main .slide .slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu {
            background-color: $white;
        }

        .side-menu__item.active {
            color: $primary-1 !important;
        }

        .side-menu .sub-side-menu__label,
        .sub-slide-label,
        .sub-side-menu__label2 {
            color: $default-color;
        }

        .sub-side-menu__label.active,
        .sub-side-menu__label:hover,
        .sub-side-menu__label:focus,
        .sub-side-menu__label1.active,
        .sub-side-menu__label1:hover,
        .sub-side-menu__label1:focus,
        .sub-side-menu__label2.active,
        .sub-side-menu__label2:hover,
        .sub-side-menu__label2:focus {
            color: $primary-1;
        }
    }

    //Transparent color-menu
    &.color-menu {
        .app-sidebar {
            background: var(--primary-bg-color);
        }

        &.horizontal {
            .horizontal-main.fixed-header {
                background-color: $primary-1 !important;
            }
        }

        @media (max-width: 992.98px) {
            &.horizontal {
                .horizontal-main {
                    background-color: $primary-1 !important;
                }
            }

            &.app.sidebar-mini .app-sidebar {
                background-color: $primary-1 !important
            }
        }
    }

    //Transparent dark-menu
    &.dark-menu {
        .app-sidebar {
            background: $dark-theme-1;
        }

        &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: $dark-theme-1;
            inset-inline-start: 107px;
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: $dark-theme-1;
            inset-inline-start: 67px;
        }

        &.horizontal {
            .horizontal-main.fixed-header {
                background-color: $dark-theme-1 !important;
            }
        }

        @media (max-width: 992.98px) {
            &.horizontal {
                .horizontal-main {
                    background-color: $dark-theme-1 !important;
                }
            }

            &.app.sidebar-mini .app-sidebar {
                background-color: $dark-theme-1 !important
            }
        }
    }

    //Transparent gradient-menu
    &.gradient-menu {

        // .side-menu {
        //     background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
        // }
        .app-sidebar {
            background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
        }

        &.sidenav-toggled.sidenav-toggled-open .app-sidebar {
            background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
        }

        &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: $dark-theme-1;
            inset-inline-start: 107px;
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: $dark-theme-1;
            inset-inline-start: 67px;
        }

        @media (max-width: 992.98px) {
            &.horizontal {
                .horizontal-main {
                    background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
                }
            }

            &.app.sidebar-mini .app-sidebar {
                background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
            }
        }
    }

    &.header-light .app-header.sticky.stickyClass {
        background-color: $white !important;
    }

    &.color-header {
        .app-header.sticky.stickyClass {
            background-color: $primary-1 !important;
        }

        .header.hor-header {
            background-color: $primary-1 !important;
        }
    }

    &.dark-header {
        .app-header.sticky.stickyClass {
            background-color: $dark-theme-1 !important;
        }

        .header.hor-header {
            background-color: $dark-theme-1 !important;
        }
    }
}

@media (min-width: 992px) {
    .transparent-mode {
        &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: var(--transparent-body);
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            background: var(--transparent-body);
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu-label1 {
            border-block-end-color: $border-dark !important;
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu-label1 a {
            color: $text-color !important;
        }

        &.hover-submenu,
        &.hover-submenu1 {
            &.sidenav-toggled.sidenav-toggled-open .app-sidebar {
                background: $transparent-theme !important;
            }
        }

        .slide-menu li .slide-item:before {
            color: $white;
        }

        :not(.light-menu),
        :not(.color-menu),
        :not(.dark-menu),
        :not(.gradient-menu) {
            &.sidenav-toggled.sidenav-toggled-open .app-sidebar {
                background: $primary-1;

                .side-menu {
                    background: $primary-1;
                }
            }
        }

        @supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
            &.sidenav-toggled.sidenav-toggled-open .app-sidebar {
                background: $black-1 !important;
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);

                .side-menu {
                    background: transparent !important;
                    backdrop-filter: blur(20px);
                    -webkit-backdrop-filter: blur(20px);
                }

                .side-header {
                    backdrop-filter: blur(20px);
                    -webkit-backdrop-filter: blur(20px);
                }
            }
        }

        &.sidebar-mini .side-menu__item {

            &.active .side-menu__icon,
            &:hover .side-menu__icon,
            &:focus .side-menu__icon,
            &.active,
            &:hover,
            &:focus {
                color: $white;
            }
        }

        @supports (-webkit-backdrop-filter: inherit) or (backdrop-filter: inherit) {
            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
                backdrop-filter: inherit;
                -webkit-backdrop-filter: inherit;
            }
        }

        //Transparent light-menu
        &.light-menu {
            .app-sidebar {
                background-color: $white !important;
            }

            .side-menu__item {
                color: $default-color;
            }

            .side-menu .side-menu__icon {
                color: $primary-1;
            }

            .side-header {
                border-block-end: 1px solid $border;
                background: $white !important;
            }

            .side-menu .sub-category,
            .sub-side-menu__item,
            .sub-slide-item,
            .sub-side-menu__item2,
            .sub-slide-item2 {
                color: #74829c;
            }

            .slide.is-expanded a {
                color: $default-color;
            }

            .slide-menu li .slide-item:before {
                color: #68798b;
            }

            .slide-menu li .slide-item {
                color: $default-color;
            }

            .app-sidebar.sidemenu-scroll .side-header {
                background: $white !important;
            }

            &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
                background: $white;
            }

            &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
                .slide-menu {
                    background: $white;
                }

                .side-menu-label1 {
                    border-block-end: 1px solid $border;

                    a {
                        color: $default-color;
                    }
                }
            }

            .side-header .header-brand-img {
                &.light-logo1 {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }
            }

            &.sidenav-toggled .side-header .header-brand-img {
                &.light-logo {
                    display: block !important;
                }

                &.toggle-logo {
                    display: none !important;
                }
            }

            &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: none !important;
                }
            }

            &.app.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: none !important;
                }

                &.light-logo {
                    display: block !important;
                }
            }

            &.app.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: none !important;
                }

                &.light-logo {
                    display: block !important;
                }
            }

            &.horizontal .side-menu>li>a {
                color: $default-color;
            }

            &.horizontal .horizontal-main .slide .slide-menu,
            .horizontal .horizontal-main .slide .sub-slide-menu {
                background-color: $white;
            }

            &.horizontal .side-menu .sub-side-menu__label,
            .transparent-mode .sub-slide-label,
            .transparent-mode .sub-side-menu__label2 {
                color: $default-color;
            }

            &.horizontal .horizontal-main .slide .slide-menu,
            &.horizontal .horizontal-main .slide .sub-slide-menu,
            &.horizontal .horizontal-main .slide .sub-slide-menu2 {
                background-color: $white;
            }

            .side-menu__item.active {
                color: $primary-1 !important;
            }

            .side-menu .sub-side-menu__label,
            .sub-slide-label,
            .sub-side-menu__label2,
            .sub-slide-item {
                color: $default-color;
            }

            .sub-side-menu__label.active,
            .sub-side-menu__label:hover,
            .sub-side-menu__label:focus,
            .sub-side-menu__label1.active,
            .sub-side-menu__label1:hover,
            .sub-side-menu__label1:focus,
            .sub-side-menu__label2.active,
            .sub-side-menu__label2:hover,
            .sub-side-menu__label2:focus {
                color: $primary-1;
            }

            &.color-header,
            &.dark-header,
            &.gradient-header {

                &.hover-submenu,
                &.hover-submenu1 {
                    .sticky.stickyClass .side-header {
                        background-color: $white !important;
                    }
                }
            }
        }

        //Transparent color-menu
        &.color-menu {
            .app-sidebar {
                background: var(--primary-bg-color);
            }

            &.dark-header {

                &.hover-submenu,
                &.hover-submenu1 {
                    .sticky.stickyClass .side-header {
                        background-color: $primary-1 !important;
                    }
                }
            }
        }

        //Transparent dark-menu
        &.dark-menu {
            .app-sidebar {
                background: $dark-theme-1;
            }

            &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
                background: $dark-theme-1;
                inset-inline-start: 107px;
            }

            &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
                background: $dark-theme-1;
                inset-inline-start: 67px;
            }

            &.color-header {

                &.hover-submenu,
                &.hover-submenu1 {
                    .sticky.stickyClass .side-header {
                        background-color: $dark-theme-1 !important;
                    }
                }
            }
        }

        //Transparent gradient-menu
        &.gradient-menu {
            &.dark-header {

                &.hover-submenu,
                &.hover-submenu1 {
                    .sticky.stickyClass .side-header {
                        background-color: $primary-1 !important;
                    }
                }
            }

            .app-sidebar {
                background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
            }

            &.sidenav-toggled.sidenav-toggled-open .app-sidebar {
                background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
            }

            &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
                background: $dark-theme-1;
                inset-inline-start: 107px;
            }

            &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
                background: $dark-theme-1;
                inset-inline-start: 67px;
            }
        }

        &.header-light.color-menu {
            .side-header .header-brand-img.desktop-logo {
                display: block !important;
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
                .app-sidebar .side-header .header-brand-img {
                    &.light-logo1 {
                        display: none !important;
                    }

                    &.desktop-logo {
                        display: block !important;
                    }
                }

                .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
                    &.desktop-logo {
                        display: block !important;
                    }
                }
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: none !important;
                }

                &.desktop-logo {
                    display: block !important;
                }

                .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
                    &.desktop-logo {
                        display: block !important;
                    }
                }
            }

            &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.toggle-logo {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }
            }

            &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.toggle-logo {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }
            }

            .side-header {
                background-color: $primary-1;
            }

            &.hover-submenu .sticky.stickyClass .side-header,
            &.hover-submenu1 .sticky.stickyClass .side-header {
                background-color: $primary-1 !important;
            }

            .app-sidebar.sidemenu-scroll .side-header {
                background: $primary-1 !important;
            }
        }

        &.header-light.dark-menu {
            .side-header .header-brand-img.desktop-logo {
                display: block !important;
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
                .app-sidebar .side-header .header-brand-img {
                    &.light-logo1 {
                        display: none !important;
                    }

                    &.desktop-logo {
                        display: block !important;
                    }
                }

                .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
                    &.desktop-logo {
                        display: block !important;
                    }
                }
            }

            &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.toggle-logo {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }
            }

            &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.toggle-logo {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }
            }

            &.hover-submenu .sticky.stickyClass .side-header,
            &.hover-submenu1 .sticky.stickyClass .side-header {
                background-color: $dark-theme-1 !important;
            }

            .app-sidebar.sidemenu-scroll .side-header {
                background: $dark-theme-1 !important;
            }
        }

        &.header-light.gradient-menu {
            .side-header .header-brand-img.desktop-logo {
                display: block !important;
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
                .app-sidebar .side-header .header-brand-img {
                    &.light-logo1 {
                        display: none !important;
                    }

                    &.desktop-logo {
                        display: block !important;
                    }
                }

                .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
                    &.desktop-logo {
                        display: block !important;
                    }
                }
            }

            &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.toggle-logo {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: none !important;
                }
            }

            &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.toggle-logo {
                    display: block !important;
                }

                &.desktop-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: none !important;
                }
            }

            .side-header {
                background: $primary-1 !important;
            }

            &.hover-submenu .sticky.stickyClass .side-header,
            &.hover-submenu1 .sticky.stickyClass .side-header {
                background: $primary-1 !important;
            }

            .app-sidebar.sidemenu-scroll .side-header {
                background: $primary-1 !important;
            }
        }

        &.header-light {
            .side-header {
                background-color: $white;
            }

            .app-sidebar.sidemenu-scroll .side-header {
                background: $white !important;
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.desktop-logo {
                    display: none !important;
                }

                &.light-logo1 {
                    display: block !important;
                }
            }

            &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: none !important;
                }

                &.toggle-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: block !important;
                }
            }

            &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
                &.light-logo1 {
                    display: none !important;
                }

                &.toggle-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: block !important;
                }
            }

            &.hover-submenu,
            &.hover-submenu1 {
                .sticky.stickyClass .side-header {
                    background-color: $white !important;
                }
            }
        }

        &.color-header {
            .side-header {
                background-color: $primary-1;
            }

            &.hover-submenu,
            &.hover-submenu1 {
                .sticky.stickyClass .side-header {
                    background-color: $primary-1 !important;
                }
            }
        }

        &.dark-header {

            &.hover-submenu,
            &.hover-submenu1 {
                .sticky.stickyClass .side-header {
                    background-color: $dark-theme-1 !important;
                }
            }
        }
    }
}

.transparent-mode {
    &.bg-img1 {
        .login-img {
            background-image: none;
        }
    }

    &.bg-img2 {
        .login-img {
            background-image: none;
        }
    }

    &.bg-img3 {
        .login-img {
            background-image: none;
        }
    }

    &.bg-img4 {
        .login-img {
            background-image: none;
        }
    }

    .login-img {
        background-image: none;
    }
}

.layout-boxed .login-img {
    .page {
        background: rgba(0, 0, 0, 0.1);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.5) 0px 5px 25px 0px;
    }
}


.transparent-mode.hover-submenu .sticky.stickyClass {
    .side-header {
        background: $primary-1;
    }
}

@supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
    .transparent-mode.hover-submenu .sticky.stickyClass {
        .side-header {
            background: transparent !important;
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
        }
    }
}

.transparent-mode.hover-submenu1 .sticky.stickyClass {
    .side-header {
        background: $primary-1;
    }
}

@supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
    .transparent-mode.hover-submenu1 .sticky.stickyClass {
        .side-header {
            background: transparent !important;
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
        }
    }
}

@media (max-width: 991px) {
    .transparent-mode.header-light .logo-horizontal .header-brand-img.desktop-logo {
        display: none !important;
    }

    .transparent-mode.header-light.sidebar-mini .app-header .light-logo1 {
        display: block !important;
    }

    .gradient-header .responsive-navbar .navbar-collapse {
        background: linear-gradient(to right, $primary-1 0%, #8e77fa 100%);
    }

    .dark-mode.header-light .responsive-navbar .navbar-collapse {
        background-color: $white;
    }

    .dark-mode.header-light .app-sidebar__toggle {
        color: $primary-1;
    }

    .dark-mode.header-light .navresponsive-toggler span {
        color: $primary-1;
    }

    .dark-mode.color-header .responsive-navbar .navbar-collapse {
        background-color: $primary-1;
    }

    .dark-header.horizontal {
        .responsive-navbar {
            .navbar-collapse {
                .nav-link {
                    color: $default-color !important;
                }
            }
        }
    }
}

.transparent-mode {
    &.header-light {
        .app-header {
            background: $white !important;
        }

        .app-header.sticky-pin {
            background: $white;
        }

        .hor-header {
            background: $white !important;
        }

        .side-header .header-brand-img {
            &.light-logo1 {
                display: block !important;
            }

            &.desktop-logo {
                display: none !important;
            }
        }

        .app-sidebar__toggle {
            color: $primary-1;
        }

        .main-header-center .form-control {
            border-color: $border;
        }

        .app-header .header-right-icons .nav-link.icon {
            color: $primary-1 !important;
        }

        .app-header .header-right-icons .nav-link.icon:hover {
            color: $primary-1 !important;
        }

        &.sidenav-toggled .header-brand-img.light-logo {
            display: block !important;
        }

        .hor-header .header-right-icons .nav-link.icon {
            color: $primary-1 !important;
        }

        .main-header-center .form-control {
            color: #4d5875 !important;
        }

        &.horizontal.header-light .logo-horizontal .header-brand-img.desktop-logo {
            display: none !important;
        }

        &.horizontal.header-light .logo-horizontal .header-brand-img.light-logo1 {
            display: block;
        }

        .main-header-center .form-control {
            &::placeholder {
                color: $default-color !important;
            }
        }

        .main-header-center .btn {
            color: #b4bdce;
        }
    }

    &.color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.dark-mode.light-menu {

    .side-menu .sub-side-menu__label,
    .sub-slide-label,
    .sub-side-menu__label2 {
        color: $default-color !important;
    }
}

.dark-mode.color-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent;
}

.dark-mode.gradient-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent;
}

.dark-mode.light-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent;
}

.dark-mode.color-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent;
}

.dark-mode.gradient-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent;
}

.dark-mode.light-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent;
}

.dark-mode .main-container {
    .onoffswitch2-label {
        background-color: $dark-body;
        border: 1px solid $border-dark;
    }

    .onoffswitch2-label:before {
        background-color: #2a2a4a;
        border: 1px solid $border-dark;
    }

    .input-color-picker {
        background-color: $dark-theme-1;
    }

    .input-dark-color-picker {
        background-color: $dark-theme-1;
    }

    .input-transparent-color-picker {
        background-color: $dark-theme-1;
    }
}

.transparent-mode .main-container {
    .onoffswitch2-label {
        background-color: $transparent-theme;
        border: 1px solid $border-dark;
    }

    .onoffswitch2-label:before {
        background-color: $transparent-body;
        border: 1px solid $border-dark;
    }

    .input-color-picker {
        background-color: $transparent-body;
    }

    .input-dark-color-picker {
        background-color: $transparent-body;
    }

    .input-transparent-color-picker {
        background-color: $transparent-body;
    }
}

.color-header.color-menu {
    &.sidenav-toggled {
        .header-brand-img.light-logo {
            display: none !important;
        }
    }
}

.header-light.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: none !important;
    }
}

.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
        display: block !important;
    }
}

.transparent-mode.dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
}

.header-light.sidenav-toggled.transparent-mode .header-brand-img.toggle-logo {
    display: none !important;
}

.header-light.sidenav-toggled.sidenav-toggled-open.transparent-mode .header-brand-img.toggle-logo {
    display: none !important;
}

.transparent-mode.gradient-menu.header-light.sidenav-toggled .header-brand-img.light-logo {
    display: none !important;
}

.header-light.sidenav-toggled.transparent-mode.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
}

.header-light.transparent-mode.sidenav-toggled.dark-menu .header-brand-img.toggle-logo {
    display: block !important;
}

.header-light.transparent-mode.sidenav-toggled.sidenav-toggled-open.dark-menu .header-brand-img.toggle-logo {
    display: none !important;
}


.transparent-mode.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    background: $dark !important;
}

.transparent-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    background: $white !important;
}

.color-menu .sub-slide-item,
.color-menu .sub-slide-item2 {
    color: #e7eefb;
}

.dark-mode.header-light .app-sidebar__toggle {
    color: var(--primary-bg-color) !important;
}

.switch-toggle {
    .bg-img1 #bgimage1 {
        width: 50px;
        height: 50px;
    }

    .bg-img2 #bgimage2 {
        width: 50px;
        height: 50px;
    }

    .bg-img3 #bgimage3 {
        width: 50px;
        height: 50px;
    }

    .bg-img4 #bgimage4 {
        width: 50px;
        height: 50px;
    }
}

.dark-mode.light-menu .app-sidebar .side-menu .slide a {
    color: $dark;
}

.switch_section p {
    margin-block-end: 0px;
}

.dark-mode.light-menu.header-light.scrollable-layout .app-sidebar {
    border-inline-end: 1px solid #e6e6e6;
}

.transparent-mode.header-light.scrollable-layout .app-sidebar {
    border-inline-end: 1px solid rgba(185, 185, 185, 0.22);
}

.dark-mode.color-menu .side-menu .sub-side-menu__label,
.dark-mode.color-menu .side-menu .sub-side-menu__label2 {
    color: $white !important;
}

.dark-mode.gradient-menu .side-menu .sub-side-menu__label,
.dark-mode.gradient-menu .side-menu .sub-side-menu__label2 {
    color: $white !important;
}

.dark-mode.header-light .country span:hover {
    color: $primary-1 !important;
}

.dark-mode.header-light .app-header .header-right-icons .nav-link.icon:hover {
    color: $primary-1 !important;
}

.hover-submenu1.header-light.transparent-mode.sidenav-toggled.sidenav-toggled-open.dark-menu .header-brand-img.toggle-logo {
    display: block !important;
}

.hover-submenu.header-light.transparent-mode.sidenav-toggled.sidenav-toggled-open.dark-menu .header-brand-img.toggle-logo {
    display: block !important;
}

.dark-mode.color-menu .app-sidebar .side-menu .slide a.active {
    opacity: 1;
    color: $white !important;
}

.dark-mode.light-menu .side-menu .slide a.active .sub-side-menu__label {
    color: $primary-1 !important;
}

.dark-mode .side-menu .slide a.active .sub-side-menu__label {
    color: $primary-1;
}

.transparent-mode.light-menu .side-menu .slide a.active .sub-side-menu__label {
    color: $primary-1;
}

.transparent-mode.light-menu .app-sidebar .side-menu .slide a.active {
    color: $primary-1 !important;
}

.dark-mode.gradient-menu .app-sidebar .side-menu .slide a.active {
    opacity: 1;
    color: $white !important;
}

.transparent-mode.color-menu .side-menu .slide a.active .sub-side-menu__label {
    color: $white;
}

.transparent-mode.dark-menu .side-menu .slide a.active .sub-side-menu__label {
    color: $primary-1;
}

.transparent-mode.gradient-menu .side-menu .slide a.active .sub-side-menu__label {
    color: $white;
}

@media (min-width: 992px) {

    .color-menu .main-sidemenu .slide-left,
    .color-menu .main-sidemenu .slide-right {
        color: #fff;
        border: 1px solid $white-3;
        background: $primary-1;
    }

    .color-menu .main-sidemenu .slide-left svg,
    .color-menu .main-sidemenu .slide-right svg {
        fill: #fff;
    }

    .dark-menu .main-sidemenu .slide-left,
    .dark-menu .main-sidemenu .slide-right {
        color: #fff;
        border: 1px solid $white-3;
        background: $dark-theme-1;
    }

    .dark-menu .main-sidemenu .slide-left svg,
    .dark-menu .main-sidemenu .slide-right svg {
        fill: #fff;
    }

    .gradient-menu .main-sidemenu .slide-left,
    .gradient-menu .main-sidemenu .slide-right {
        color: #fff;
        border: 1px solid $white-3;
        background: $primary-1;
    }

    .gradient-menu .main-sidemenu .slide-left svg,
    .gradient-menu .main-sidemenu .slide-right svg {
        fill: #fff;
    }

    .main-sidemenu .slide-left,
    .main-sidemenu .slide-right {
        background: $white;
        border: 1px solid $border;
    }

    .main-sidemenu .slide-left svg,
    .main-sidemenu .slide-right svg {
        fill: $default-color;
    }

    .dark-mode {

        .main-sidemenu .slide-left,
        .main-sidemenu .slide-right {
            background: $dark-theme-1;
            border: 1px solid $border-dark;
        }

        .main-sidemenu .slide-left svg,
        .main-sidemenu .slide-right svg {
            fill: $white;
        }

        &.color-menu .main-sidemenu .slide-left,
        &.color-menu .main-sidemenu .slide-right {
            background: $primary-1;
        }

        &.gradient-menu .main-sidemenu .slide-left,
        &.gradient-menu .main-sidemenu .slide-right {
            background: $primary-1;
        }

        &.light-menu .main-sidemenu .slide-left,
        &.light-menu .main-sidemenu .slide-right {
            background: $white;
            border: 1px solid $border;
        }

        &.light-menu .main-sidemenu .slide-left svg,
        &.light-menu .main-sidemenu .slide-right svg {
            fill: $default-color;
        }
    }

    .transparent-mode {

        .main-sidemenu .slide-left,
        .main-sidemenu .slide-right {
            background: $transparent-theme;
            border: 1px solid $border-dark;
        }

        .main-sidemenu .slide-left svg,
        .main-sidemenu .slide-right svg {
            fill: $white;
        }

        &.light-menu .main-sidemenu .slide-left,
        &.light-menu .main-sidemenu .slide-right {
            background: $white;
            border: 1px solid $border;
        }

        &.light-menu .main-sidemenu .slide-left svg,
        &.light-menu .main-sidemenu .slide-right svg {
            fill: $default-color;
        }

        &.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar {
            background: $primary-1 !important;
        }

        &.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar {
            background: #2a2a4a !important;
        }
    }
}

@media (max-width: 991px) {
    .dark-mode.color-header .header.hor-header {
        box-shadow: 0 8px 24px transparent;
    }

    .dark-mode.dark-header .header.hor-header {
        box-shadow: 0 8px 24px transparent;
    }

    .dark-mode.gradient-header .header.hor-header {
        box-shadow: 0 8px 24px transparent;
    }
}

.horizontal.transparent-mode.header-light.light-menu {
    .hor-header.header {
        border-block-end-color: $border;
    }
}

@media (max-width: 320px) {
    .app .app-sidebar {
        inset-block-start: 72px;
    }

    .transparent-mode.app.light-menu .app-sidebar {
        inset-block-start: 72px !important;
    }
}

.app.sidebar-mini.transparent-mode.header-light.sidenav-toggled.hover-submenu1.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: block !important;
    }
}

.app.sidebar-mini.transparent-mode.header-light.sidenav-toggled.hover-submenu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: block !important;
    }
}

@media (max-width: 992px) {
    .horizontal.dark-mode.sidenav-toggled {
        .header.hor-header {
            box-shadow: none;
        }
    }
}

.light-mode {
    &.bg-img1 .form_holder {
        background: $white !important;
    }

    &.bg-img2 .form_holder {
        background: $white !important;
    }

    &.bg-img3 .form_holder {
        background: $white !important;
    }

    &.bg-img4 .form_holder {
        background: $white !important;
    }
}

.dark-mode {
    &.bg-img1 .form_holder {
        background: $dark-theme-1 !important;
    }

    &.bg-img2 .form_holder {
        background: $dark-theme-1 !important;
    }

    &.bg-img3 .form_holder {
        background: $dark-theme-1 !important;
    }

    &.bg-img4 .form_holder {
        background: $dark-theme-1 !important;
    }
}

.app.sidebar-mini.transparent-mode.sidenav-toggled.header-light {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.header-light.light-menu.sidenav-toggled.sidenav-toggled-open {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.light-menu.header-light {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.light-logo1 {
        display: block !important;
    }
}

.app.sidebar-mini.transparent-mode.light-menu.header-light {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.header-light.light-menu.sidenav-toggled {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.light-logo1 {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.header-light.light-menu.sidenav-toggled.sidenav-toggled-open {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.light-logo1 {
        display: block !important;
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu.sidenav-toggled.header-light.color-menu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu.sidenav-toggled.header-light.dark-menu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu1.sidenav-toggled.header-light.gradient-menu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu1.sidenav-toggled.header-light.color-menu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu1.sidenav-toggled.header-light.dark-menu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu1.sidenav-toggled.header-light.gradient-menu.sidenav-toggled-open {
    .side-header .header-brand-img.light-logo {
        display: none !important;
    }
}

.app.sidebar-mini.transparent-mode.light-menu.header-light.hover-submenu1.sidenav-toggled.sidenav-toggled-open {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.light-logo1 {
        display: none !important;
    }
}

.app.sidebar-mini.header-light.sidenav-toggled.hover-submenu.transparent-mode.sidenav-toggled-open {
    .app-sidebar.sidemenu-scroll .side-header .header-brand-img.desktop-logo {
        display: none !important;
    }
}

@media screen and (min-width: 992px) {
    .header-light.transparent-mode.sidenav-toggled.sidenav-toggled-open .sidemenu-scroll {
        .side-header {
            .header-brand-img {
                &.desktop-logo {
                    display: block !important;
                }

                &.toggle-logo {
                    display: none !important;
                }
            }
        }
    }

    .header-light.light-menu.transparent-mode.sidenav-toggled .sidemenu-scroll {
        .side-header {
            .header-brand-img {
                &.toggle-logo {
                    display: none !important;
                }

                &.light-logo {
                    display: block !important;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__icon {
        color: #282f53 !important;
    }

    .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__icon {
        color: #282f53 !important;
    }

    .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:hover .side-menu__icon,
    .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:focus .side-menu__icon {
        color: $primary-1 !important;
    }

    .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:hover .side-menu__icon,
    .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:focus .side-menu__icon {
        color: $primary-1 !important;
    }

    .hover-submenu.transparent-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
        color: $primary-1 !important;
    }

    .hover-submenu1.transparent-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
        color: $primary-1 !important;
    }

    .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
        color: $primary-1 !important;
    }

    .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
        color: $primary-1 !important;
    }
}

@media screen and (max-width: 992px) {
    .app.sidebar-mini.sidebar-gone.sidenav-toggled.light-menu {
        .side-menu__icon {
            color: $primary-1 !important;
        }
    }
}

.app.sidebar-mini.transparent-mode.hover-submenu.sidenav-toggled.header-light.gradient-menu.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
}

.app.sidebar-mini.transparent-mode.hover-submenu1.sidenav-toggled.header-light.gradient-menu.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
}

.app.sidebar-mini.transparent-mode.hover-submenu.sidenav-toggled.header-light.gradient-menu.sidenav-toggled-open .header-brand-img.toggle-logo {
    display: block !important;
}

.app.sidebar-mini.transparent-mode.hover-submenu1.sidenav-toggled.header-light.gradient-menu.sidenav-toggled-open .header-brand-img.toggle-logo {
    display: block !important;
}

.color-menu .side-menu .side-menu__item.active {
    color: $white !important;
}

@media (max-width: 991.98px) {
    .header-light.light-menu.transparent-mode.sidebar-gone.sidenav-toggled {
        .app-header.header {
            border-block-end: 1px solid #e9edf4;
        }
    }

    .header-light.light-menu.transparent-mode {
        .app-header.header {
            border-block-end: 1px solid #e9edf4;
        }
    }
}

.dark-mode.color-menu .sub-slide-item.active,
.dark-mode.color-menu .sub-slide-item:hover,
.dark-mode.color-menu .sub-slide-item:focus,
.dark-mode.color-menu .sub-slide-item2.active,
.dark-mode.color-menu .sub-slide-item2:hover,
.dark-mode.color-menu .sub-slide-item2:focus,
.dark-mode.color-menu .sub-side-menu__label.active,
.dark-mode.color-menu .sub-side-menu__label:hover,
.dark-mode.color-menu .sub-side-menu__label:focus,
.dark-mode.color-menu .sub-side-menu__label1.active,
.dark-mode.color-menu .sub-side-menu__label1:hover,
.dark-mode.color-menu .sub-side-menu__label1:focus,
.dark-mode.color-menu .sub-side-menu__label2.active,
.dark-mode.color-menu .sub-side-menu__label2:hover,
.dark-mode.color-menu .sub-side-menu__label2:focus {
    color: $white !important;
}

@media(max-width:992px) {
    .transparent-mode.horizontal.light-menu {

        .horizontal-main .slide .slide-menu,
        .horizontal-main .slide .sub-slide-menu,
        .horizontal-main .slide .sub-slide-menu2 {
            background-color: $white;
        }
    }
}

.onoffswitch2-checkbox:checked+.onoffswitch2-label {
    background-color: $primary-1;
}

.onoffswitch2-checkbox:checked+.onoffswitch-label2,
.onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
    border-color: $primary-1;
}


@media (min-width: 992px) {

    .transparent-mode,
    .dark-mode,
    .light-mode {
        &.light-menu {

            &.hover-submenu1,
            &.hover-submenu {
                &.sidebar-mini.sidenav-toggled {
                    .app-sidebar .side-header .header-brand-img {
                        &.toggle-logo {
                            display: none !important;
                        }

                        &.light-logo {
                            display: block !important;
                        }
                    }

                    &.sidenav-toggled-open {
                        .app-sidebar .side-header .header-brand-img {
                            &.toggle-logo {
                                display: none !important;
                            }

                            &.light-logo {
                                display: block !important;
                            }
                        }
                    }
                }
            }
        }

        &.dark-menu,
        &.color-menu,
        &.gradient-menu {

            &.hover-submenu1,
            &.hover-submenu {
                &.sidebar-mini.sidenav-toggled {
                    .app-sidebar .side-header .header-brand-img {
                        &.toggle-logo {
                            display: block !important;
                        }

                        &.light-logo {
                            display: none !important;
                        }
                    }

                    &.sidenav-toggled-open {
                        .app-sidebar .side-header .header-brand-img {
                            &.toggle-logo {
                                display: block !important;
                            }

                            &.light-logo {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .gradient-menu,
    .color-menu,
    .dark-menu {
        .slide {

            .tab-content i {
                color: $white-7;
            }
        }

        &.hover-submenu,
        &.hover-submenu1 {

            &.app.sidebar-mini.sidenav-toggled .side-menu__item {

                &:hover,
                &:focus {

                    .side-menu__icon,
                    .side-menu__label {
                        color: $white !important;
                    }
                }
            }
        }
    }
}