.popover {
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: start;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #dee3eb;
	border-radius: 3px;
	-webkit-filter: drop-shadow(0 1px 3px $black-1);
	filter: drop-shadow(0 1px 3px $black-1);

	.popover-arrow {
		position: absolute;
		display: block;
		width: 0.5rem;
		height: 0.5rem;
		margin: 0 3px;
		margin-inline-start: calc(.25rem + -5px);

		&::before,
		&::after {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^="top"] {
	margin-block-end: 0.5rem;
}

.bs-popover-top .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
	inset-block-end: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
	border-width: 0.5rem 0.25rem 0;
}

.bs-popover-top .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before {
	inset-block-end: 0;
	border-block-start-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
	inset-block-end: 1px;
	border-block-start-color: $white;
}

.bs-popover-end,
.bs-popover-auto[data-popper-placement^="right"] {
	margin-inline-start: 0.5rem;
}

.bs-popover-end .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
	inset-inline-end: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}

.bs-popover-end .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before,
.bs-popover-end .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
	border-block-start-width: 0.25rem;
	border-inline-end-width: 0.5rem;
	border-block-end-width: 0.25rem;
	border-inline-start-width: 0px;
}

.bs-popover-end .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
	inset-inline-start: 0;
	border-inline-end-color: #dee3eb;
}

.bs-popover-end .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
	inset-inline-start: 1px;
	border-inline-end-color: $white;
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^="bottom"] {
	margin-block-start: 0.5rem;
}

.bs-popover-bottom .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
	inset-block-start: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before,
.bs-popover-bottom .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
	border-width: 0 0.25rem 0.5rem 0.25rem;
}

.bs-popover-bottom .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
	inset-block-start: 0;
	border-block-end-color: #dee3eb;
}

.bs-popover-bottom .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
	inset-block-start: 1px;
	border-block-end-color: $white;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 50%;
	display: block;
	width: 0.5rem;
	margin-inline-start: -0.25rem;
	content: "";
	border-block-end: 1px solid $white;
}

.bs-popover-start,
.bs-popover-auto[data-popper-placement^="left"] {
	margin-inline-end: 0.5rem;
}

.bs-popover-start .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
	inset-inline-end: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}

.bs-popover-start .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before,
.bs-popover-start .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
	border-block-start-width: 0.25rem;
	border-inline-end-width: 0px;
	border-block-end-width: 0.25rem;
	border-inline-start-width: 0.5rem;
}

.bs-popover-start .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
	inset-inline-end: 0;
	border-inline-start-color: #dee3eb;
}

.bs-popover-start .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
	inset-inline-end: 1px;
	border-inline-start-color: $white;
}

.popover-header {
	padding: 0.5rem 0.75rem;
	margin-block-end: 0;
	font-size: 0.9375rem;
	color: inherit;
	background-color: $white;
	border-block-end: 1px solid #ebebeb;
	border-start-start-radius: 5px;
	border-start-end-radius: 5px;

	&:empty {
		display: none;
	}
}

.popover-body {
	padding: 0.75rem 1rem;
	color: #6e7687;
}

.popover {

	&.bs-popover-top,
	&.bs-popover-auto[data-popper-placement^="top"] {
		margin-block-end: 0.625rem;
	}
}

.popover-static-demo .popover {
	position: relative;
}