/*------Drop Downs-------*/

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-inline-start: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-block-start: 0.3em solid;
        border-inline-end: 0.3em solid transparent;
        border-block-end: 0;
        border-inline-start: 0.3em solid transparent;
    }
    &:empty::after {
        margin-inline-start: 0;
    }
}

.dropdown-menu {
    position: absolute;
    inset-block-start: 100%;
    inset-inline-start: 0;
    z-index: 1000;
    display: none;
    float: $float-left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.9375rem;
    color: #495057;
    text-align: start;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $border;
}

.dropup {
    .dropdown-menu {
        inset-block-start: auto;
        inset-block-end: 100%;
        margin-block-start: 0;
        margin-block-end: 0.125rem;
    }
    .dropdown-toggle {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-inline-start: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-block-start: 0;
            border-inline-end: 0.3em solid transparent;
            border-block-end: 0.3em solid;
            border-inline-start: 0.3em solid transparent;
        }
        &:empty::after {
            margin-inline-start: 0;
        }
    }
}

.dropright {
    .dropdown-menu {
        inset-block-start: 0;
        inset-inline-end: auto;
        inset-inline-start: 100%;
        margin-block-start: 0;
        margin-inline-start: 0.125rem;
    }
    .dropdown-toggle {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-inline-start: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-block-start: 0.3em solid transparent;
            border-inline-end: 0;
            border-block-end: 0.3em solid transparent;
            border-inline-start: 0.3em solid;
        }
        &:empty::after {
            margin-inline-start: 0;
        }
    }
}

.dropleft {
    .dropdown-menu {
        inset-block-start: 0;
        inset-inline-end: 100%;
        inset-inline-start: auto;
        margin-block-start: 0;
        margin-inline-end: 0.125rem;
    }
    .dropdown-toggle {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-inline-start: 0.255em;
            vertical-align: 0.255em;
            content: "";
        }
        &::before {
            display: inline-block;
            width: 0;
            height: 0;
            margin-inline-end: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-block-start: 0.3em solid transparent;
            border-inline-end: 0.3em solid;
            border-block-end: 0.3em solid transparent;
        }
        &:empty::after {
            margin-inline-start: 0;
        }
        &::before {
            vertical-align: 0;
        }
    }
}

.dropdown-menu {
    &[data-popper-placement^="top"],
    &[data-popper-placement^="right"],
    &[data-popper-placement^="bottom"],
    &[data-popper-placement^="left"] {
        inset-inline-end: auto;
        inset-block-end: auto;
    }
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-block-start: 1px solid #e9ecef;
}

.drop-heading {
    padding: 1rem 1rem;
    color: #8f9daf;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem !important;
    clear: both;
    font-weight: 400;
    color: #6e84a3;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item {
    &.user {
        font-size: 18px;
        padding: 5px;
    }
    &:hover,
    &:focus,
    &.active,
    &:active {
        text-decoration: none;
        background-color: $primary-005;
    }
    &.disabled,
    &:disabled {
        color: $white;
        background-color: transparent;
    }
}

.dropdown-menu.show {
    display: block;
    margin: 0;
    border: 1px solid #eaf2f6;
    box-shadow: 0 3px 9px 0 rgba(104, 113, 123, 0.15);
    border-radius: 7px;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-block-end: 0;
    font-size: 0.875rem;
    color: #868e96;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}


/*-----List Of Dropdwons-----*/

btn.dropdown-toggle~.dropdown-menu {
    background-color: rgb(244, 244, 244) !important;
    background-color: rgb(255, 255, 255) !important;
    border: 0 solid rgb(66, 133, 244) !important;
    box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    inset-block-start: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
    background-color: rgb(244, 244, 244) !important;
    background-color: rgb(255, 255, 255) !important;
    border: 0 solid rgb(66, 133, 244) !important;
    box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    inset-block-start: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    position: absolute !important;
    width: 100% !important;
}

.dropdown-menu {
    position: absolute;
    inset-block-start: 100%;
    inset-inline-start: 0;
    z-index: 8;
    display: none;
    float: $float-left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    background-color: $white;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    >li>a {
        display: block;
        padding: 8px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.428571429;
        color: #76839a;
        white-space: nowrap;
        &:hover,
        &:focus {
            text-decoration: none;
            color: #76839a;
            background-color: $primary-005;
        }
    }
    .divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: $border;
    }
    .dropdown-plus-title {
        width: 100% !important;
        color: $default-color !important;
        padding: 6px 12px !important;
        font-weight: 500 !important;
        border: 0 solid $border !important;
        border-block-end-width: 1px !important;
        cursor: pointer !important;
    }
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
    padding-block-start: 10px !important;
    padding-block-end: 10px !important;
    line-height: 20px !important;
}

.btn.dropdown-toggle {
    &.btn-primary~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(53, 126, 189) !important;
    }
    &.btn-success~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(76, 174, 76) !important;
    }
    &.btn-info~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(70, 184, 218) !important;
    }
    &.btn-warning~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(238, 162, 54) !important;
    }
    &.btn-danger~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(212, 63, 58) !important;
    }
}

.dropdown-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    border-block-end: 1px solid $border;
}

.dropdown-menu-header label {
    margin-block-end: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #76839a;
}

.media {
    display: flex;
    align-items: flex-start;
}

@media (min-width: 768px) {
    ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
        padding-block-start: 15px !important;
        padding-block-end: 15px !important;
    }
}

@media (min-width: 768px) {
    ul.dropdown-menu li.dropdown ul.dropdown-menu {
        width: auto !important;
    }
}

.dropdown-menu-arrow:before {
    content: '';
    width: 13px;
    height: 13px;
    background: $white;
    position: absolute;
    inset-block-start: -7px;
    inset-inline-end: 28px;
    transform: rotate(45deg);
    border-block-start: 1px solid $border;
    border-inline-start: 1px solid $border;
}

.dropdown-menu.header-search:before {
    content: '';
    width: 13px;
    height: 13px;
    background: $white;
    position: absolute;
    inset-block-start: -7px;
    inset-inline-start: 28px;
    transform: rotate(45deg);
    border-block-start: 1px solid $border;
    border-inline-start: 1px solid $border;
}

.dropdown {
    display: block;
}

.header .dropdown .nav-link.icon:hover {
    background: none !important;
}

.dropdown-menu {
    box-shadow: 0 1px 2px 0 $black-05;
    min-width: 12rem;
}

.dropdown-menu-arrow.dropdown-menu-end {
    &:before,
    &:after {
        inset-inline-start: auto;
        inset-inline-end: 18px;
    }
}

.dropdown-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    &:after {
        vertical-align: 0.155em;
    }
    &:empty:after {
        margin-inline-start: 0;
    }
}

.dropdown-icon {
    color: #8f9daf;
    margin-inline-end: .5rem;
    margin-inline-start: -.5rem;
    width: 1em;
    display: inline-block;
    text-align: center;
    vertical-align: -1px;
}

.dropdown-toggle .dropdown-label {
    position: relative;
    inset-block-start: -18px;
    inset-inline-start: -12px;
    background-color: #fff;
    padding: 0px 10px 0px 10px;
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    background-color: $success;
    border-color: $success;
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: $white;
    background-color: $info;
    border-color: $info;
}