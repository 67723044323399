.tree {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-inline-start: 1em;
        position: relative;

        ul {
            margin-inline-start: 1em;
        }

        &:before {
            content: "";
            display: block;
            width: 0;
            position: absolute;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-start: -10px;
        }
    }

    li {
        margin: 0;
        line-height: 2em;
        color: #9eabc0;
        position: relative;

        /* border: 1px solid $border;
      margin: 10px 0;
      border-radius: 5px;
      line-height: 30px; */

        i {
            margin-inline-end: 10px;
            font-weight: bold;

            /* font-size: 16px; */
            /* padding-block-start: 10px; */
            line-height: 1;
            float: $float-left;
            margin-block-start: 0.5rem;
        }
    }

    ul li {
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 0;
            margin-block-start: -1px;
            position: absolute;
            inset-block-start: 13px;
            inset-inline-start: -10px;
        }

        &:last-child:before {
            background: transparent;
            height: auto;
            inset-block-start: 14px;
            inset-block-end: 0;
        }
    }
}

.indicator {
    margin-inline-end: 5px;
    color: #4b10a4;
}

.tree {
    li {
        a {
            text-decoration: none;
            color: #74829c;
            font-size: 15px;
        }

        button {
            text-decoration: none;
            color: #29273c;
            border: none;
            background: transparent;
            margin: 0;
            padding: 0;
            outline: 0;

            &:active,
            &:focus {
                text-decoration: none;
                color: #29273c;
                border: none;
                background: transparent;
                margin: 0;
                padding: 0;
                outline: 0;
            }
        }
    }

    ul {
        &:before {
            border-inline-start: 1px dotted #9eabc0;
        }

        li:before {
            border-block-start: 1px dotted #9eabc0;
        }
    }
}

/* Treeview checked */

* {
    margin: 0;
    padding: 0;
}

#page-wrap {
    margin: auto 0;
}

.treeview {
    margin: 10px 0 0 20px;
}

ul {
    list-style: none;
}

.treeview {
    li {
        background: url(http://jquery.bassistance.de/treeview/images/treeview-default-line.gif) 0 0 no-repeat;
        padding: 2px 0 2px 16px;

        &.last {
            background-position: 0 -1766px;
        }

        > {
            input {
                height: 16px;
                width: 16px;

                /* hide the inputs but keep them in the layout with events (use opacity) */
                opacity: 0;
                filter: alpha(opacity=0);

                /* internet explorer */
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";

                /*IE8*/
            }

            label {
                background: url(https://www.thecssninja.com/demo/css_custom-forms/gr_custom-inputs.png) 0 -1px no-repeat;

                /* move left to cover the original checkbox area */
                margin-inline-start: -20px;

                /* pad the text to make room for image */
                padding-inline-start: 20px;
            }
        }
    }

    .custom-unchecked {
        background-position: 0 -1px;

        &:hover {
            background-position: 0 -21px;
        }
    }

    .custom-checked {
        background-position: 0 -81px;

        &:hover {
            background-position: 0 -101px;
        }
    }

    .custom-indeterminate {
        background-position: 0 -141px;

        &:hover {
            background-position: 0 -121px;
        }
    }
}