.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    > {
        .form-control,
        .form-select,
        .form-file {
            position: relative;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            margin-block-end: 0;
        }
        .form-control:focus,
        .form-select:focus,
        .form-file:focus {
            z-index: 0;
        }
        .form-control+ {
            .form-control,
            .form-select,
            .form-file {
                margin-inline-start: -1px;
            }
        }
        .form-select+ {
            .form-control,
            .form-select,
            .form-file {
                margin-inline-start: -1px;
            }
        }
        .form-file+ {
            .form-control,
            .form-select,
            .form-file {
                margin-inline-start: -1px;
            }
        }
        .form-control:not(:last-child),
        .form-select:not(:last-child) {
            border-start-end-radius: 0;
            border-end-end-radius: 0;
        }
        .form-control:not(:first-child),
        .form-select:not(:first-child) {
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }
        .form-file {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            &:not(:last-child) .form-file-label {
                border-start-end-radius: 0;
                border-end-end-radius: 0;
                &::after {
                    border-start-end-radius: 0;
                    border-end-end-radius: 0;
                }
            }
            &:not(:first-child) .form-file-label {
                border-start-start-radius: 0;
                border-end-start-radius: 0;
                &::after {
                    border-start-start-radius: 0;
                    border-end-start-radius: 0;
                }
            }
        }
    }
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding:.640rem 0.75rem;
    margin-block-end: 0;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 0.9;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: $primary-1;
    border: 1px solid $border;
    border-start-end-radius: 7px;
    border-end-end-radius: 7px;
    border-start-start-radius: 5px;
    border-end-start-radius: 5px;
    border-inline-end: 0;
    input {
        &[type="radio"],
        &[type="checkbox"] {
            margin-block-start: 0;
        }
    }
}

.input-group-btn {
    font-size: 0.9375rem;
    border: 0;
}

.input-group-btn>.btn {
    height: 100%;
    border-color: rgb(36, 196, 180);
}

.input-group-text {
    border-inline-end: 0;
}

.input-group-text {
    border-inline-start: 0;
}

.input-group-addon {
    padding: 10px 0px 6px 12px;
    width: 38px;
    height: 38px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1 !important;
    color: #555;
    text-align: center;
    border-inline-end: 0;
    border-radius: 4px 0 0 4px;
}

.input-indec .input-group-btn>.btn {
    height: 100%;
    border-color: $border;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    > {
        .form-control,
        .form-select,
        .form-file {
            position: relative;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            margin-block-end: 0;
        }
        .form-control:focus,
        .form-select:focus,
        .form-file:focus {
            z-index: 0;
        }
        .form-control+ {
            .form-control,
            .form-select,
            .form-file {
                margin-inline-start: -1px;
            }
        }
        .form-select+ {
            .form-control,
            .form-select,
            .form-file {
                margin-inline-start: -1px;
            }
        }
        .form-file+ {
            .form-control,
            .form-select,
            .form-file {
                margin-inline-start: -1px;
            }
        }
        .form-control:not(:last-child),
        .form-select:not(:last-child) {
            border-start-end-radius: 0;
            border-end-end-radius: 0;
        }
        .form-control:not(:first-child),
        .form-select:not(:first-child) {
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }
        .form-file {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            &:not(:last-child) .form-file-label {
                border-start-end-radius: 0;
                border-end-end-radius: 0;
                &::after {
                    border-start-end-radius: 0;
                    border-end-end-radius: 0;
                }
            }
            &:not(:first-child) .form-file-label {
                border-start-start-radius: 0;
                border-end-start-radius: 0;
                &::after {
                    border-start-start-radius: 0;
                    border-end-start-radius: 0;
                }
            }
        }
    }
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.640rem 0.75rem;
    margin-block-end: 0;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    border: 1px solid $border;
    input {
        &[type="radio"],
        &[type="checkbox"] {
            margin-block-start: 0;
        }
    }
}

.input-icon .form-group.is-empty {
    .form-control {
        margin-block-end: 0;
    }
    margin-block-end: 0;
}

.input-group-addon {
    padding: 10px 0px 6px 12px;
    width: 38px;
    height: 38px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1 !important;
    color: #555;
    text-align: center;
    border-inline-end: 0;
    border-radius: 4px 0 0 4px;
}

.input-indec .input-group-btn>.btn {
    height: 100%;
    border-color: $border;
}