.accordionjs {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-block-start: 10px;
    margin-block-end: 20px;

    .acc_section {
        border: 1px solid #eee;
        position: relative;
        z-index: 1;
        margin-block-start: -1px;
        overflow: hidden;

        .acc_head {
            position: relative;
            background: #fff;
            padding: 10px;
            display: block;
            cursor: pointer;

            h3 {
                line-height: 1;
                margin: 5px;
                font-size: 14px;
                padding-inline-start: 25px;
                font-weight: 200;
            }
        }

        .acc_content {
            padding: 10px;
        }

        &:first-of-type {
            border-start-start-radius: 5px;
            border-start-end-radius: 5px;

            .acc_head {
                border-start-start-radius: 5px;
                border-start-end-radius: 5px;
            }
        }

        &:last-of-type {
            border-end-start-radius: 5px;
            border-end-end-radius: 5px;

            .acc_content {
                border-end-start-radius: 5px;
                border-end-end-radius: 5px;
            }
        }

        &.acc_active {
            > {
                .acc_content {
                    display: block;
                }

                .acc_head {
                    border-block-end: 1px solid #eee;
                    color: #fff;
                }
            }

            .acc_head h3:before {
                content: "\e995";
                position: absolute;
                font-family: "feather" !important;
                inset-inline-start: 13px;
                color: #fff;
                inset-block-start: 14px;
                font-size: 15px;
                transition: all 0.5s;
                transform: scale(1);
            }
        }

        .acc_head h3:before {
            content: "\e9b0";
            position: absolute;
            font-family: "feather" !important;
            inset-inline-start: 12px;
            inset-block-start: 14px;
            font-size: 15px;
            transition: all 0.5s;
            transform: scale(1);
        }
    }
}
