/* Ion.RangeSlider, Flat UI Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
    background: url(../plugins/ion.rangeSlider/img/sprite-skin-simple.png) repeat-x;
}

.irs {
    height: 40px;
}

.irs-with-grid {
    height: 60px;
}

.irs-line {
    height: 12px;
    inset-block-start: 25px;
}

.irs-line-left {
    height: 12px;
    background-position: 0 -30px;
}

.irs-line-mid {
    height: 12px;
    background-position: 0 0;
}

.irs-line-right {
    height: 12px;
    background-position: 100% -30px;
}

.irs-bar {
    height: 12px;
    inset-block-start: 25px;
    background-position: 0 -60px;
}

.irs-bar-edge {
    inset-block-start: 25px;
    height: 12px;
    width: 9px;
    background-position: 0 -90px;
}

.irs-shadow {
    height: 3px;
    inset-block-start: 34px;
    background: #000;
    opacity: 0.25;
}

.lt-ie9 .irs-shadow {
    filter: alpha(opacity = 25);
}

.irs-slider {
    width: 16px;
    height: 18px;
    inset-block-start: 22px;
    background-position: 0 -120px;

    &.state_hover,
    &:hover {
        background-position: 0 -150px;
    }
}

.irs-min,
.irs-max {
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    inset-block-start: 0;
    padding: 1px 3px;
    background: #e1e4e9;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.irs-from,
.irs-to,
.irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: #ed5565;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
    position: absolute;
    display: block;
    content: "";
    inset-block-end: -6px;
    inset-inline-start: 50%;
    width: 0;
    height: 0;
    margin-inline-start: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-block-start-color: $primary-1;
}

.irs-grid-pol {
    background: #e1e4e9;
}

.irs-grid-text {
    color: #999;
}
