.header-search {
    min-width: 18rem !important;
}

.header {
    background: $white;
    padding-block-start: .75rem;
    padding-block-end: .75rem;
    border-block-end: 1px solid $border;
}

@media print {
    .header {
        display: none;
    }
}

.header {
    .dropdown-menu {
        margin-block-start: 0;
    }

    .mega-menu {
        width: 350px;
    }

    .nav-tabs {
        margin: 0;
    }
}

.header-right-icons {
    a {
        padding-block-start: 9px;
    }

    .dropdown-menu a {
        padding: 12px;
    }

    .profile-1 a {
        padding-block-start: 5px;
    }
}

.header-brand {
    color: inherit;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.6rem;
}

.app-header .header-brand {
    color: inherit;
    margin-inline-end: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 200px;
    text-align: center;

    .header-brand-img {
        margin-inline-end: 0;
    }
}

.header-brand:hover {
    color: inherit;
    text-decoration: none;
}

.header-brand-img {
    line-height: 2rem;
    vertical-align: middle;
    width: auto;
}

.header-avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
}

.header-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 991px) {
    .header .form-inline {
        margin-inline-end: -2px;
    }
}

@media (max-width: 991px) {
    .sidebar-mini.sidenav-toggled {
        .side-header {
            display: none;
        }
    }

    .header.hor-header {
        position: fixed;
        border-block-end: 1px solid $border;
        width: 100%;
        z-index: 999;
        inset-block-start: 0;
    }

    .header.hor-header {
        .header-brand-img {
            &.light-logo {
                display: none;
            }

            &.desktop-logo {
                display: none;
                margin: 0 auto;
            }

            &.light-logo1 {
                display: block;
            }
        }

        .header-brand1 {
            width: 100%;
            margin: 0 auto;
            position: absolute;
            inset-inline-start: 0;
            inset-inline-end: 0;
            text-align: center;
            margin-block-start: 0 !important;
        }
    }
}

@media (max-width: 991px) {
    .header-brand-img {
        margin-inline-start: 1.5rem;
        margin: 3px auto;
    }

    .side-header {
        display: none !important;
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    .animated-arrow {
        cursor: pointer;
        padding-block-start: 25px !important;
        padding-inline-end: 35px !important;
        padding-block-end: 16px !important;
        padding-inline-start: 0px !important;
        margin: 11px 0 0 15px;
    }
}

@media (min-width: 768px) {
    .app-content .side-app {
        padding-block-start: 0px !important;
    }
}

.header {
    .form-inline {
        .form-control {
            border-color: rgba(225, 225, 225, 0.1);
            padding-inline-start: 20px;
            width: 220px;
            padding-inline-end: 40px;
            background: rgba(225, 225, 225, 0.3);
            color: $black !important;
        }

        .btn {
            border-radius: 0;
            padding: 5px 15px 6px 15px;
            border: 2px solid transparent;
            box-shadow: none;
            border-end-end-radius: 3px;
            border-start-end-radius: 3px;
            background: transparent;
            color: $white;
            margin-inline-start: -56px;
            font-size: 15px;
        }
    }

    .dropdown-menu {
        padding: 0;
        border: 0;
    }
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .header .form-inline .search-element {
        position: absolute;
        inset-block-start: 8px;
        inset-inline-start: 10px;
        inset-inline-end: 10px;
        z-index: 892;
        display: none;
    }

    body.search-show .header .form-inline .search-element {
        display: block;
    }

    .header .navsearch i {
        font-size: 20px;
        color: $white;
    }

    .search-element .form-control {
        float: $float-left;
        width: 100% !important;
        height: 40px;
        margin: 0 auto;
        background: $white !important;
        color: $black;
    }

    .search-show .search-element {
        display: block;
        inset-inline-start: 0 !important;
        inset-block-start: 0 !important;
        inset-inline-end: 0;
        width: 100%;
        padding: 10px 13px;
    }

    .header {
        .form-inline .form-control::-webkit-input-placeholder {
            color: #76839a;
        }

        .navsearch i {
            font-size: 20px;
            color: $white;
            padding-block-start: 5px;
        }

        .form-inline .btn {
            color: #46494a !important;
            padding: 8px 15px 6px 15px;
        }
    }
}

@media (max-width: 568px) {
    .page-main .header {
        inset-block-start: 0px !important;
    }
}

.animated-arrow span {

    &:before,
    &:after {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .animated-arrow span {

        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }

    .animated-arrow span {

        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
}

.header-style .header-brand {
    margin-inline-start: 0 !important;
}

@media only screen and (max-width: 991px) {
    .page-header {
        padding: 4px;
    }

    .app-header {
        box-shadow: none !important;
        position: fixed !important;
    }

    .page {
        position: inherit;
        background: none;
    }

    body {
        background-color: #f6f6fb;
    }

    .page-main {
        z-index: inherit;
    }
}

@media only screen and (min-width: 768px) {
    .header .form-inline .form-control {
        width: 200px;
    }
}

.hor-header {
    .header-brand1 {
        color: inherit;
        font-size: 1.25rem;
        white-space: nowrap;
        font-weight: 600;
        padding: 0;
        transition: .3s opacity;
        line-height: 2.8rem;
        margin-block-start: 2px;
    }
}

.header-right-icons {
    .nav-link.icon:hover {
        background: none;
        border-radius: 50px;
    }

    .profile-user:hover {
        box-shadow: none;
    }
}

@media (max-width: 480px) {

    .app-header .header-brand,
    .app-header1 .header-brand {
        min-width: auto;
    }

    .header-right-icons .dropdown .header-toggler {
        margin-block-start: 9px;
    }
}

@media (max-width: 991px) {
    .header {
        border-block-end: 1px solid $white-2;
        padding-block-start: 0.95rem;
        padding-block-end: 0.95rem;
    }
}

.header {
    .notifications {
        .dropdown-menu {
            min-width: 18rem;
        }
    }

    .dropdown.message {
        .dropdown-menu {
            min-width: 19rem;
        }
    }

    .dropdown.notifications,
    .dropdown.message {
        .dropdown-item {
            padding: 1rem !important;
        }
    }

    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        inset-block-start: 3.5rem !important;

        .badge {
            padding: 3px 6px;
        }

        .dropdown-item {
            border-block-end: 1px solid $border;

            &:last-child {
                border-block-end: 0;
            }
        }
    }

    .dropdown-item {
        color: $default-color;
    }

    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }

        span {
            font-size: 12px;
            color: #76839a;
        }
    }

    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;

        .badge {
            padding: 3px 6px;
        }

        .dropdown-item {
            border-block-end: 1px solid $border;

            &:last-child {
                border-block-end: 0;
            }
        }
    }

    .message-menu .wd-90p {
        width: 90%;
    }

    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;

            .dropdown-icon {
                font-size: 16px;
                color: $primary-1;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    inset-block-start: 5px;
                    inset-block-end: 0;
                    border-radius: 50%;
                    inset-inline-end: 3px;
                    background: rgba($primary-1, 0);
                }
            }
        }
    }
}

.responsive-navbar {

    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }

        span {
            font-size: 12px;
            color: #76839a;
        }
    }

    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;

        .badge {
            padding: 4px 8px;
        }

        .dropdown-item {
            border-block-end: 1px solid $border;

            &:last-child {
                border-block-end: 0;
            }
        }
    }

    .message-menu .wd-90p {
        width: 90%;
    }

    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;

            .dropdown-icon {
                font-size: 16px;
                color: $primary-1;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    inset-block-start: 5px;
                    inset-block-end: 0;
                    border-radius: 50%;
                    inset-inline-end: 3px;
                    background: rgba($primary-1, 0);
                }
            }
        }
    }
}

.dropdown .avatar.profile-user {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
}

@media only screen and (max-width: 460px) {
    .profile-cover__img .profile-img-1>img {
        margin-inline-start: -29px;
    }
}

.app-header,
.hor-header {
    .responsive-navbar {

        .notifications-menu,
        .message-menu {

            .dropdown-item h5,
            .dropdown-item span {
                white-space: normal !important;
            }
        }

        .notifications-menu .wd-80p {
            width: 80%;
        }
    }

    .profile-1 {
        .dropdown-item {
            white-space: normal;
        }
    }
}

.header {
    .header-dropdown-list {
        height: 300px;
    }

    .notifications-menu {
        height: 300px;
    }

    .message-menu-scroll {
        height: 290px;
    }
}

.dropdown-footer {
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
}

.shopping-cart,
.notifications,
.message {
    .ps__rail-y {
        height: 234px !important;
    }

    .ps__thumb-y {
        height: 118px !important;
        background-color: transparent;
    }

    .ps__rail-y:hover>.ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: transparent;
    }

    .header-dropdown-list {
        .wd-50p {
            width: 50% !important;
        }
    }
}

.main-header-center {
    .form-control.dropdown-toggle {
        -webkit-user-select: text;
        user-select: text;
    }
}