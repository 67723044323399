.no-gutters {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
}
.gutters-0 {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
	.card {
		margin-block-end: 0;
	}
}
.gutters-xs {
	margin-inline-end: -0.25rem;
	margin-inline-start: -0.25rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.25rem;
			padding-inline-start: 0.25rem;
		}
	}
	.card {
		margin-block-end: 0.5rem;
	}
}
.gutters-sm {
	margin-inline-end: -0.5rem;
	margin-inline-start: -0.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.5rem;
			padding-inline-start: 0.5rem;
		}
	}
	.card {
		margin-block-end: 1rem;
	}
}
.gutters-lg {
	margin-inline-end: -1rem;
	margin-inline-start: -1rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 1rem;
			padding-inline-start: 1rem;
		}
	}
	.card {
		margin-block-end: 2rem;
	}
}
.gutters-xl {
	margin-inline-end: -1.5rem;
	margin-inline-start: -1.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 1.5rem;
			padding-inline-start: 1.5rem;
		}
	}
	.card {
		margin-block-end: 3rem;
	}
}
.no-gutters {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
}
.gutters-0 {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
	.card {
		margin-block-end: 0;
	}
}
.gutters-xs {
	margin-inline-end: -0.25rem;
	margin-inline-start: -0.25rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.25rem;
			padding-inline-start: 0.25rem;
		}
	}
	.card {
		margin-block-end: 0.5rem;
	}
}
.gutters-sm {
	margin-inline-end: -0.5rem;
	margin-inline-start: -0.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.5rem;
			padding-inline-start: 0.5rem;
		}
	}
	.card {
		margin-block-end: 1rem;
	}
}
.gutters-lg {
	margin-inline-end: -1rem;
	margin-inline-start: -1rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 1rem;
			padding-inline-start: 1rem;
		}
	}
	.card {
		margin-block-end: 2rem;
	}
}
.gutters-xl {
	margin-inline-end: -1.5rem;
	margin-inline-start: -1.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 1.5rem;
			padding-inline-start: 1.5rem;
		}
	}
	.card {
		margin-block-end: 3rem;
	}
}
.no-gutters {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
}
.gutters-0 {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
	.card {
		margin-block-end: 0;
	}
}
.gutters-xs {
	margin-inline-end: -0.25rem;
	margin-inline-start: -0.25rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.25rem;
			padding-inline-start: 0.25rem;
		}
	}
	.card {
		margin-block-end: 0.5rem;
	}
}
.gutters-sm {
	margin-inline-end: -0.5rem;
	margin-inline-start: -0.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.5rem;
			padding-inline-start: 0.5rem;
		}
	}
	.card {
		margin-block-end: 1rem;
	}
}
.gutters-lg {
	margin-inline-end: -1rem;
	margin-inline-start: -1rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 1rem;
			padding-inline-start: 1rem;
		}
	}
	.card {
		margin-block-end: 2rem;
	}
}
.gutters-xl {
	margin-inline-end: -1.5rem;
	margin-inline-start: -1.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 1.5rem;
			padding-inline-start: 1.5rem;
		}
	}
	.card {
		margin-block-end: 3rem;
	}
}