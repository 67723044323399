@media (max-width: 320px) {
    .fc-toolbar .fc-right {
        float: $float-right;
        width: 100%;
        text-align: center;
        margin: 10px 0;
    }
}

.login-content:after {
    content: "";
}

#footer,
.fc-toolbar .ui-button,
.fileinput .thumbnail {
    text-align: center;
}

.richText {
    .richText-toolbar {
        ul li a {
            border-inline-end: rgba(156, 162, 161, 0.2) solid 1px;
        }

        border-block-end: rgba(156, 162, 161, 0.1) solid 1px;
    }

    border: $border solid 1px;
    background-color: #f6f6fb !important;
}

.cal1 {
    .clndr {
        .clndr-table {
            .header-days .header-day {
                border-inline-start: 1px solid $border;
                border-block-start: 1px solid $border;
                border-inline-end: 1px solid $border;
            }

            tr {

                .empty,
                .adjacent-month,
                .my-empty,
                .my-adjacent-month {
                    border-inline-start: 1px solid $border;
                    border-block-start: 1px solid $border;
                    color: #76839a;
                }

                .day {

                    &.event,
                    &.my-event {
                        background: #fafafb !important;
                    }

                    border-inline-start: 1px solid $border;
                    border-block-start: 1px solid $border;

                    &:last-child {
                        border-inline-end: 1px solid $border;
                    }

                    &:hover {
                        background: #f6f6fb;
                    }
                }
            }
        }

        .clndr-controls .clndr-control-button {

            .clndr-previous-button,
            .clndr-next-button {
                color: $white;
            }
        }
    }

    font-size: 14px;
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $border;
    }
}

.fc-event,
.fc-event-dot {
    color: $border ! important;
}

.fc-unthemed {

    .fc-divider,
    .fc-list-heading td,
    .fc-popover .fc-header {
        background: $border;
    }
}

.fc-toolbar {

    .fc-state-active,
    .ui-state-active {
        background: #b4b4b4;
    }
}

.fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
    border: none !important;
    box-shadow: none !important;
}

.fc-unthemed .fc-list-item:hover td {
    background-color: #eeeeee;
}

.cal1 .clndr .clndr-table tr {

    .empty:hover,
    .adjacent-month:hover,
    .my-empty:hover,
    .my-adjacent-month:hover {
        background: $white;
    }
}

.error-page {
    .display-1 {
        font-size: 8.5rem;
        margin-block-start: 0px;
    }

    position: relative;
}

@media (max-width: 480px) {
    .cal1 .clndr .clndr-controls {
        display: grid !important;
    }

    .cal1 .clndr .clndr-controls .month {
        width: 100% !important;
        text-align: start !important;
    }

    .cal1 .clndr .clndr-controls .clndr-control-button.rightalign {
        text-align: start !important;
    }
}
.alert-inner--icon {
    display: inline-flex !important;
}