.label {
	display: inline-block;
	margin-block-end: .5rem;
	padding-inline-end: .6em;
	padding-inline-start: .6em;
}

.labels li {
	display: inline;
}

.label-default {
	background: #d5e0ec;
	color: $white;
}

.label-success {
	background: $success;
	color: $white;
}

.label-danger {
	background: #f5334f;
	color: $white;
}

.label-warning {
	background: $warning;
	color: $white;
}

.label-info {
	background: $info;
	color: $white;
}