.panel-title-landing {
    color: #1643A3 !important;
    font-size: 35px;
    font-weight: bold;
}

.panel-footer-landing {
    border: solid 2px #1643A3 !important;
    border-end-start-radius: 10px !important;
    border-end-end-radius: 10px !important;
    border-block-start: none !important;
    text-align: center;
    background: #f7f7f7;
}

.panel-body {
    padding: 15px;
    .lead {
        strong {
            font-size: 40px;
            margin-block-end: 0;
        }
        font-size: 20px;
        margin-block-end: 0;
        padding: 10px 0;
    }
}

.panel-footer {
    padding: 10px 15px;
    background-color: $white;
    border-block-start: 1px solid $border;
    border-inline-start: 1px solid $border;
    border-inline-end: 1px solid $border;
    border-end-end-radius: 3px;
    border-end-start-radius: 3px;
}

.panel-group .panel,
.panel-group1 .panel {
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid $border;
    overflow: hidden;
}

.panel-heading1 a.active {
    border-radius: 5px 5px 0 0;
}

.panel-default>.panel-heading {
    padding: 0;
    border-radius: 0;
    background-color: #f6f6fb;
    border-color: $border;
}

.panel-title {
    font-size: 14px;
    margin-block-end: 0;
    >a {
        display: block;
        padding: 15px;
        text-decoration: none;
    }
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 0px solid $border;
    border-block-start: 0;
}

.panel1 {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $white;
    background: none;
    box-shadow: none;
    &:last-child {
        border-block-end: none;
    }
}

.panel-group1 {
    >.panel1:first-child .panel-heading1 {
        border-radius: 4px 4px 0 0;
    }
    .panel1 {
        border-radius: 0;
    }
}

.panel-body1 {
    padding: 10px;
    background: $white;
}

.panel-title1 {
    font-size: 14px;
    margin-block-end: 0;
}

.panel-group1 .panel1+.panel1 {
    margin-block-start: 0;
}

.panel-heading1 {
    background-color: #5797fc;
    border-radius: 5px;
    border: none;
    color: $white;
    padding: 0;
}

.panel-group1 .panel-body {
    border: 0px solid $border;
}

.panel-title1 a {
    display: block;
    color: $white !important;
    padding: 15px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
}

.panel1:last-child {
    .panel-body1 {
        border-radius: 0 0 4px 4px;
    }
    .panel-heading1 {
        border-radius: 0 0 4px 4px;
        transition: border-radius 0.3s linear 0.2s;
        &.active {
            border-radius: 0;
            transition: border-radius linear 0s;
        }
    }
}


/* #bs-collapse icon scale option */

.panel-title a.accordion-toggle {
    &:before {
        content: "\f068";
        padding: 0 10px 0 0;
        color: $white;
        font-family: FontAwesome;
        float: $float-right;
    }
    &.collapsed:before {
        content: "\f067";
        padding: 0 10px 0 0;
        color: $white;
        font-family: FontAwesome;
        float: $float-right;
    }
}

.panel-heading1 {
    a {
        &.collapsed:before {
            content: "\e9af";
            position: absolute;
            font-family: 'feather' !important;
            inset-inline-end: 16px;
            inset-block-start: 15px;
            font-size: 15px;
            transition: all 0.5s;
            transform: scale(1);
        }
        &:before {
            content: "\e994";
            position: absolute;
            font-family: 'feather' !important;
            inset-inline-end: 16px;
            inset-block-start: 15px;
            font-size: 15px;
            transition: all 0.5s;
            transform: scale(1);
        }
    }
    &.active a:before {
        content: ' ';
        transition: all 0.5s;
        transform: scale(0);
    }
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
    margin-block-end: 0;
}


/*--------panel----------*/

.expanel-default {
    border: #ddd !important;
}

.expanel-secondary {
    border: $secondary !important;
}

.expanel-success {
    border: $green !important;
}

.expanel-danger {
    border: #c21a1a !important;
}

.expanel-primary>.expanel-heading {
    color: $white !important;
}

.expanel-secondary>.expanel-heading {
    color: $white !important;
    background-color: $secondary !important;
    border-color: $secondary !important;
}

.expanel-success>.expanel-heading {
    color: $white !important;
    background-color: $green !important;
    border-color: $green !important;
}

.expanel-danger>.expanel-heading {
    color: $white !important;
    background-color: #c21a1a !important;
    border-color: #c21a1a !important;
}

.expanel-warning>.expanel-heading {
    color: $white !important;
    background-color: #ecd938 !important;
    border-color: #ecd938 !important;
}

.expanel-title {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    font-size: 16px !important;
    color: inherit !important;
}

.expanel {
    margin-block-end: 20px;
    background-color: $white !important;
    border: 1px solid $border !important;
    border-radius: 4px !important;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
}

.expanel-default>.expanel-heading {
    background-color: $primary-005 !important;
    border-color: #f6f6fb !important;
}

.expanel-heading {
    padding: 10px 15px !important;
    border-block-end: 1px solid $border;
    border-start-start-radius: 3px !important;
    border-start-end-radius: 3px !important;
}

.expanel-footer {
    padding: 10px 15px !important;
    background-color: $primary-005 !important;
    border-block-start: 1px solid $border !important;
    border-end-end-radius: 3px !important;
    border-end-start-radius: 3px !important;
}

.expanel-body {
    padding: 15px !important;
}